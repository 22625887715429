import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import './CatalogueSidenave.style.scss';
import { toggleSidenav } from '../../redux/actions/uiActions';
import { getAttributeValue } from '../../utils/CommonUtils';
import { Link } from 'react-router-dom';
import ButtonToTextArea from './ButtonWithText';
import { setComment } from '../../redux/actions/catalogActions';
import {
  BsArrowRightCircle,
  BsArrowLeftCircle,
  BsArrowLeft,
} from 'react-icons/bs';
import { getImageUrl } from '../../utils/helper';

function CatalogueSideNave() {
  const dispatch = useDispatch();
  const { catalog, brandUser } = useSelector((state) => state?.catalog);
  const ui = useSelector((state) => state.ui.ui);

  const url = catalog.branddetails?.brandinfo?.logo;
  const handleTogle = () => {
    dispatch(toggleSidenav());
  };
  return (
    <div className='side-nav'>
      <button className='toggle-switch' onClick={handleTogle}>
        {ui.toggleSideMenu ? (
          <BsArrowRightCircle size={22} />
        ) : (
          <BsArrowLeftCircle size={22} />
        )}
      </button>
      <div className='side-menu'>
        <div className='back-button '>
          <Link to='/catalogue-approval'>
            {' '}
            <BsArrowLeft
              size={22}
              style={{ cursor: 'pointer', marginRight: '4px' }}
            />
            <span> Back To Catalogue Approval</span>
          </Link>
        </div>
        {url && (
          <div className='logo'>
            <img src={getImageUrl(url)} alt='logo' />
          </div>
        )}
        <div className='dropdown'>
          <Form.Select disabled>
            <option>Open</option>
          </Form.Select>
        </div>
        <div className='brand-details'>
          <div className='info'>
            <div className='label'>Brand name </div>
            <div className='value'>
              {getAttributeValue(brandUser, 'brandname')}{' '}
            </div>
          </div>
          <div className='info'>
            <div className='label'>Customer name </div>
            <div className='value'>
              {getAttributeValue(brandUser, 'firstname')}{' '}
              {getAttributeValue(brandUser, 'lastname')}
            </div>
          </div>
          <div className='info'>
            <div className='label'>Email </div>
            <div className='value'>{getAttributeValue(brandUser, 'email')}</div>
          </div>
          <div className='info'>
            <div className='label'>Contact No </div>
            <div className='value'>
              {getAttributeValue(brandUser, 'mobile')}
            </div>
          </div>
        </div>
        {/* <ButtonToTextArea
          label='Add Note '
          onChange={(e) => dispatch(setComment(e.target.value))}
        /> */}
      </div>
    </div>
  );
}

export default CatalogueSideNave;
