export const ActionTypes = {
  //auth
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER',
  TOGGLE_SIDENAV: 'TOGGLE_SIDENAV',
  AUTH_LOADING: 'AUTH_LOADING',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  SET_FIRST_LOGIN: 'SET_FIRST_LOGIN',
  SET_FIRST_LOGIN_SUCCESS: 'SET_FIRST_LOGIN_SUCCESS',
  SET_FIRST_LOGIN_FAIL: 'SET_FIRST_LOGIN_FAIL',
  LOGOUT: 'LOGOUT',

  //catelog
  SET_CATALOG: 'SET_CATALOG',
  UPDATE_CATELOG: 'UPDATE_CATELOG',
  SET_CATALOG_COUNT: 'SET_CATALOG_COUNT',
  MANAGE_CATALOG_FORM_LOADING: 'MANAGE_CATALOG_FORM_LOADING',
  MANAGE_CATALOG_FORM_FAILURE: 'MANAGE_CATALOG_FORM_FAILURE',
  MANAGE_CATALOG_FORM_SUCCESS: 'MANAGE_CATALOG_FORM_SUCCESS',
  MANAGE_CATALOG_BRAND_FORM_SUCCESS: 'MANAGE_CATALOG_BRAND_FORM_SUCCESS',
  SET_CATALOG_FORM_EDITABLE: 'SET_CATALOG_FORM_EDITABLE',
  FETCH_CATALOG_DETAILS_LOADING: 'FETCH_CATALOG_DETAILS_LOADING',
  FETCH_CATALOG_DETAILS_SUCCESS: 'FETCH_CATALOG_DETAILS_SUCCESS',
  FETCH_CATALOG_DETAILS_FAILURE: 'FETCH_CATALOG_DETAILS_FAILURE',
  SET_NEW_CATALOG_DATA: 'SET_NEW_CATALOG_DATA',
  RESET_CATALOG: 'RESET_CATALOG',
  GET_DROPDOWN_DATA: 'GET_DROPDOWN_DATA',
  GET_INDUSTRY: 'GET_INDUSTRY',
  GET_BUSSINESS_CATEGORY_DATA: 'GET_BUSSINESS_CATEGORY_DATA',
  GET_BUSSINESS_TYPE_DATA: 'GET_BUSSINESS_TYPE_DATA',
  SET_BRAND_USER: 'SET_BRAND_USER',
  GET_PROFILE: 'GET_PROFILE',
  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
  FETCH_STATES: 'FETCH_STATES',
  FETCH_CITIES: 'FETCH_CITIES',
  GET_SUPER_CATEGORY_DATA: 'GET_SUPER_CATEGORY_DATA',
  SET_BRAND_USER: 'SET_BRAND_USER',
  TOGGLE_ADD_COMMENT_MODAL: 'TOGGLE_ADD_COMMENT_MODAL',
  SET_COMMENT: 'SET_COMMENT',
  CATALOGUE_VERIFY_OR_REJECT_LOADING: 'CATALOGUE_VERIFY_OR_REJECT_LOADING',
  CATALOGUE_VERIFY_OR_REJECT_SUCCESS: 'CATALOGUE_VERIFY_OR_REJECT_SUCCESS',
  CATALOGUE_VERIFY_OR_REJECT_FAILURE: 'CATALOGUE_VERIFY_OR_REJECT_FAILURE',
  CATALOGUE_VERIFY_RESET_MESSAGE: 'CATALOGUE_VERIFY_RESET_MESSAGE',
  FETCH_ZONES: 'FETCH_ZONES',
  GET_COST_BIFURCATION_DATA: 'GET_COST_BIFURCATION_DATA',
  GET_EMPLOYEE_DETAILS_DATA: 'GET_EMPLOYEE_DETAILS_DATA',
  GET_LICENSE_TYPE_DATA: 'GET_LICENSE_TYPE_DATA',

  //User Management Actions
  GET_MANAGER_LIST: 'GET_MANAGER_LIST',

  //Master setup
  GET_MASTER_CATEGORYLIST: 'GET_MASTER_CATEGORYLIST',
  GET_FAQs_MASTER_CATEGORYLIST: 'GET_FAQs_MASTER_CATEGORYLIST',
  GET_SUB_CATEGORY_MASTER_CATEGORYLIST: 'GET_SUB_CATEGORY_MASTER_CATEGORYLIST',
  GET_MAIN_MASTER_CATEGORYLIST: 'GET_MAIN_MASTER_CATEGORYLIST',
  GET_MAIN_MASTER_COST_BIFURCATION: 'GET_MAIN_MASTER_COST_BIFURCATION',
  GET_ALL_THE_MASTER_CAT_LIST: 'GET_ALL_THE_MASTER_CAT_LIST',

  // BRAND Approval
  SET_BRANDID: 'SET_BRANDID',
  SET_SELECTED_BRAND_DETIALS: 'SET_SELECTED_BRAND_DETIALS',
};
