export const API_ENDPOINTS = {
  AUTH: {
    login: () => ({
      url: '/api/v1/login/fd-user',
      method: 'POST',
    }),
    register: () => ({
      url: '/api/v1/register/brand',
      method: 'POST',
    }),
    changeFirstPassword: () => ({
      url: '/api/v1/password/changepasswordfirst',
      method: 'POST',
    }),
    getBussinessCategory: () => ({
      url: '/api/v1/brand-business-category',
      method: 'GET',
    }),
    getProfile: () => ({
      url: `/api/v1/profile/details`,
      method: 'GET',
    }),
    forgotPassword: () => ({
      url: `/api/v1/password/forgotpassword`,
      method: 'POST',
    }),
    resetPassword: (token, type) => ({
      url: `/api/v1/password/forgotpassword/${token}/${type}`,
      method: 'PUT',
    }),
    refreshToken: () => ({
      url: '/api/v1/login/refresh',
      method: 'POST',
    }),
  },

  USER_LIST: {
    userList: () => ({
      url: '/api/v1/user/list',
      method: 'GET',
    }),
  },
  USER_PERMISSION: {
    userMenu: () => ({
      url: '/api/v1/menu/all',
      method: 'GET',
    }),
    saveMenu: () => ({
      url: '/api/v1/menu',
      method: 'POST',
    }),
  },

  BRAND_LIST: {
    brandList: () => ({
      url: '/api/v1/brand/user/list',
      method: 'GET',
    }),
    getBrandCRM: () => ({
      url: '/api/v1/brand/crm/list',
      method: 'GET',
    }),
  },
  CATALOG: {
    catalogueBrandDetails: () => ({
      url: '/api/v1/brandservice/catalogue',
      method: 'POST',
    }),
    catalogueFranchiseDetails: () => ({
      url: '/api/v1/brandservice/catalogue',
      method: 'POST',
    }),
    cataloguePagesDetails: () => ({
      url: '/api/v1/brandservice/catalogue',
      method: 'POST',
    }),
    catalogueAdditionalDetails: () => ({
      url: '/api/v1/brandservice/catalogue',
      method: 'POST',
    }),
    cataloguePhotosDocsDetails: () => ({
      url: '/api/v1/brandservice/catalogue',
      method: 'POST',
    }),
    catalogueAlternativeContactsDetails: () => ({
      url: '/api/v1/brandservice/catalogue',
      method: 'POST',
    }),

    getCatalogDetails: () => ({
      url: '/api/v1/brandservice/getcatalogue',
      method: 'POST',
    }),
    getDropdownData: () => ({
      url: '/api/v1/misc',
      method: 'GET',
    }),
    getPublicBussinessCategoryData: () => ({
      url: '/api/v1/master/category/master/list',
      method: 'GET',
    }),
    getBussinessCategoryData: () => ({
      url: '/api/v1/master/category',
      method: 'GET',
    }),
    getBussinessTypeData: (sup_cat_id) => ({
      url: `/api/v1/master_business_type/${sup_cat_id}`,
      method: 'GET',
    }),
    getCategoryData: (sup_cat_id) => ({
      url: `/api/v1/category/list/${sup_cat_id}`,
      method: 'GET',
    }),
    getSubCategoryData: (cat_id) => ({
      url: `/api/v1/subCategory/list/${cat_id}`,
      method: 'GET',
    }),
    getCostBifurcationsData: (sup_cat_id) => ({
      url: `/api/v1/cost_bifurcations/${sup_cat_id}`,
      method: 'GET',
    }),
    getEmployeeDetailsData: (sup_cat_id) => ({
      url: `/api/v1/employee_details/${sup_cat_id}`,
      method: 'GET',
    }),
    getLicenseType: (sup_cat_id) => ({
      url: `/api/v1/license/${sup_cat_id}`,
      method: 'GET',
    }),

    getIndustry: () => ({
      url: `/api/v1/master_industry`,
      method: 'GET',
    }),
    publishCatalog: (catalogueId) => ({
      url: `/api/v1/brandservice/publish/catalogue/${catalogueId}`,
      method: 'POST',
    }),
    catalogVerify: () => ({
      url: '/api/v1/brandservice/verify/catalogue',
      method: 'POST',
    }),
  },
  COUNTRY: {
    getCountries: () => ({
      url: `/api/v1/countries`,
      method: 'GET',
    }),
    getStates: () => ({
      url: `/api/v1/states`,
      method: 'GET',
    }),
    getCities: () => ({
      url: `/api/v1/cities`,
      method: 'GET',
    }),
    getZones: () => ({
      url: `/api/v1/zones`,
      method: 'GET',
    }),
  },
  CATEGORY: {
    getParentCategory: () => ({
      url: `/api/v1/content/category/pcat`,
      method: 'GET',
    }),
    getCategoryList: () => ({
      url: `/api/v1/content/category`,
      method: 'GET',
    }),
    addCategory: () => ({
      url: `/api/v1/content/category`,
      method: 'POST',
    }),
    deleteCategory: (id) => ({
      url: `/api/v1/content/category/${id}`,
      method: 'DELETE',
    }),
    getCategoryById: (id) => ({
      url: `/api/v1/content/category/${id}`,
      method: 'GET',
    }),
    updateCategory: (id) => ({
      url: `/api/v1/content/category/${id}`,
      method: 'PUT',
    }),
  },
  CONTENT: {
    getNewsBlogsVideoList: () => ({
      url: `/api/v1/content`,
      method: 'GET',
    }),
    getChildOptionsList: (pid) => ({
      url: `/api/v1/content/category/catlist/${pid}`,
      method: 'GET',
    }),
    addContent: () => ({
      url: `/api/v1/content`,
      method: 'POST',
    }),
    getContentDetailsById: (id) => ({
      url: `/api/v1/content/${id}`,
      method: 'GET',
    }),

    deleteContent: (id) => ({
      url: `/api/v1/content/${id}`,
      method: 'DELETE',
    }),

    updateContent: (id) => ({
      url: `/api/v1/content/${id}`,
      method: 'PUT',
    }),
  },

  PLAN: {
    getPlans: () => ({
      url: '/api/v1/plans',
      method: 'GET',
    }),
    getActiveSubscription: () => ({
      url: '/api/v1/active/subscription',
      method: 'GET',
    }),
    getSubscriptions: () => ({
      url: '/api/v1/subscriptions',
      method: 'GET',
    }),
    createManualSubscription: () => ({
      url: '/api/v1/manual/subscription',
      method: 'POST',
    }),
    cancelManualSubscription: () => ({
      url: '/api/v1/manual/subscription/cancel',
      method: 'POST',
    }),
    renewManualSubscription: () => ({
      url: '/api/v1/manual/subscription/renew',
      method: 'POST',
    }),

    downloadPaymentData: () => ({
      url: '/api/v1/invoice/download',
      method: 'GET',
    }),
  },

  MASTERCATEGORY: {
    getMasterCategoryList: () => ({
      url: `/api/v1/master/category`,
      method: 'GET',
    }),
    addMasterCategory: () => ({
      url: `/api/v1/master/category`,
      method: 'POST',
    }),
    fetchAllMainCategory: () => ({
      url: `/api/v1/master/category/data/listall`,
      method: 'GET',
    }),
    deleteCategory: (id) => ({
      url: `/api/v1/master/category/${id}`,
      method: 'DELETE',
    }),
    getCategoryById: (id) => ({
      url: `/api/v1/master/category/${id}`,
      method: 'GET',
    }),
    updateCategory: (id) => ({
      url: `/api/v1/master/category/${id}`,
      method: 'PUT',
    }),
    getModuleNameOptionsList: () => ({
      url: `/api/v1/template_module`,
      method: 'GET',
    }),
    // cost bifuration
    getMasterCostBifrucationList: () => ({
      url: `/api/v1/all/cost_bifurcations`,
      method: 'GET',
    }),
    addCostBufurcation: () => ({
      url: `/api/v1/cost_bifurcations`,
      method: 'POST',
    }),
    updateCostBifurcation: () => ({
      url: `/api/v1/cost_bifurcations`,
      method: 'PUT',
    }),
    deleteCostBifurcation: (id) => ({
      url: `/api/v1/cost_bifurcations/${id}`,
      method: 'DELETE',
    }),

    // business type
    getMasterBusinessTypeList: () => ({
      url: `/api/v1/all/master_business_type`,
      method: 'GET',
    }),
    addBusinessType: () => ({
      url: `/api/v1/master_business_type`,
      method: 'POST',
    }),
    updateBusinessType: () => ({
      url: `/api/v1/master_business_type`,
      method: 'PUT',
    }),
    deleteBusinessType: (id) => ({
      url: `/api/v1/master_business_type/${id}`,
      method: 'DELETE',
    }),
    // master category dropdown all list
    getAllMastercatList: () => ({
      url: `api/v1/master/category/data/listall`,
      method: 'GET',
    }),

    //license type api
    getMasterLicenseTypeList: () => ({
      url: `/api/v1/all/license`,
      method: 'GET',
    }),
    addLicenseType: () => ({
      url: `/api/v1/license`,
      method: 'POST',
    }),
    updateLicenseType: () => ({
      url: `/api/v1/license`,
      method: 'PUT',
    }),
    deleteLicenseType: (id) => ({
      url: `/api/v1/license/${id}`,
      method: 'DELETE',
    }),

    //employee info api
    getMasterEmployeeInfoList: () => ({
      url: `/api/v1/all/employee_details`,
      method: 'GET',
    }),
    addEmployeeInfo: () => ({
      url: `/api/v1/employee_details`,
      method: 'POST',
    }),
    updateEmployeeInfo: () => ({
      url: `/api/v1/employee_details`,
      method: 'PUT',
    }),
    deleteEmployeeInfo: (id) => ({
      url: `/api/v1/employee_details/${id}`,
      method: 'DELETE',
    }),
  },
  FAQs: {
    getFAQsList: () => ({
      url: `/api/v1/faq`,
      method: 'GET',
    }),
    addFAQs: () => ({
      url: `/api/v1/faq`,
      method: 'POST',
    }),
    deleteFAQs: (id) => ({
      url: `/api/v1/faq/${id}`,
      method: 'DELETE',
    }),
    getFAQsById: (id) => ({
      url: `/api/v1/faq/${id}`,
      method: 'GET',
    }),
    updateFAQs: (id) => ({
      url: `/api/v1/faq/${id}`,
      method: 'PUT',
    }),
  },
  MAINCATEGORY: {
    getMainCategoryList: () => ({
      url: `/api/v1/category`,
      method: 'GET',
    }),
    addMainCategory: () => ({
      url: `/api/v1/category`,
      method: 'POST',
    }),
    deleteCategory: (id) => ({
      url: `/api/v1/category/${id}`,
      method: 'DELETE',
    }),
    getCategoryById: (id) => ({
      url: `/api/v1/category/${id}`,
      method: 'GET',
    }),
    updateCategory: (id) => ({
      url: `/api/v1/category/${id}`,
      method: 'PUT',
    }),
  },
  SUBCATEGORY: {
    getSubCategoryList: () => ({
      url: `/api/v1/subCategory`,
      method: 'GET',
    }),
    addSubCategory: () => ({
      url: `/api/v1/subCategory`,
      method: 'POST',
    }),
    deleteCategory: (id) => ({
      url: `/api/v1/subCategory/${id}`,
      method: 'DELETE',
    }),
    getCategoryById: (id) => ({
      url: `/api/v1/subCategory/${id}`,
      method: 'GET',
    }),
    updateCategory: (id) => ({
      url: `/api/v1/subCategory/${id}`,
      method: 'PUT',
    }),
  },

  INVOICE: {
    getAllInvoices: () => ({
      url: '/api/v1/invoice',
      method: 'GET',
    }),
    createInvoice: () => ({
      url: `/api/v1/invoice`,
      method: 'POST',
    }),

    updateInvoice: () => ({
      url: `/api/v1/invoice`,
      method: 'PUT',
    }),
    generateInvoice: () => ({
      url: `/api/v1/invoice/generate`,
      method: 'POST',
    }),
    proformaToInvoice: () => ({
      url: `/api/v1/invoice/proforma/to/actual`,
      method: 'POST',
    }),
    downloadInvoice: () => ({
      url: '/api/v1/invoice/download',
      method: 'GET',
    }),
    exportInvoice: () => ({
      url: '/api/v1/invoice/export',
      method: 'GET',
    }),
    dashboardInvoice: () => ({
      url: '/api/v1/invoice/dashboard',
      method: 'GET',
    }),
    exportAllInvoices: () => ({
      url: '/api/v1/invoice/export',
      method: 'GET',
    }),
  },
  COMMON: {
    addTemplate: () => ({
      url: `/api/v1/template`,
      method: 'POST',
    }),
    getTemplate: () => ({
      url: `/api/v1/template`,
      method: 'GET',
    }),

    deleteTemplate: (id) => ({
      url: `/api/v1/subCategory/${id}`,
      method: 'DELETE',
    }),
    getTemplateById: (id) => ({
      url: `/api/v1/template/${id}`,
      method: 'GET',
    }),
    updateTemplate: () => ({
      url: `/api/v1/template`,
      method: 'PUT',
    }),
  },
  SETTINGS: {
    requestOtpForEmailorPassword: () => ({
      url: '/api/v1/user/setting/edit',
      method: 'POST',
    }),
    verifyOtpForEmail: () => ({
      url: '/api/v1/user/setting/verifyotp',
      method: 'POST',
    }),
    updateNewEmailOrPassword: () => ({
      url: '/api/v1/user/setting/update',
      method: 'POST',
    }),
    updateNewPassword: () => ({
      url: '/api/v1/user/resetpass/update',
      method: 'POST',
    }),
  },
  DASHBOARD: {
    fetchDashboardDetails: () => ({
      url: '/api/v1/dashboard',
      method: 'GET',
    }),
  },
  NEWSLETTER: {
    getNewsletter: () => ({
      url: '/api/v1/newsletter/list',
      method: 'GET',
    }),
  },
  REPORT: {
    investorReportList: () => ({
      url: '/api/v1/report/investor',
      method: 'GET',
    }),
    investorReportExport: () => ({
      url: '/api/v1/report/export/investor',
      method: 'GET',
    }),
    brandReportList: () => ({
      url: '/api/v1/report/brand',
      method: 'GET',
    }),
    brandReportExport: () => ({
      url: '/api/v1/report/export/brand',
      method: 'GET',
    }),
  },
};
