import { apiClient } from '../config/brandClient';
import { apiClient as masterClient } from '../config/masterManagementClient';
import { API_ENDPOINTS } from '../constants/endpoints';
const { CATALOG } = API_ENDPOINTS;

export const postCatalogueBrandDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOG.catalogueBrandDetails();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export const postCatalogueFranchiseDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOG.catalogueFranchiseDetails();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export const postCataloguePagesDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOG.cataloguePagesDetails();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export const postCatalogueAdditionalDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOG.catalogueAdditionalDetails();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const postCataloguePhotosDocslDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOG.cataloguePhotosDocsDetails();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export const postCatalogueAlternativeContactDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOG.catalogueAlternativeContactsDetails();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOG.getCatalogDetails(payload);
      const res = await apiClient({
        url: url,
        method,
        data: payload,
        showError: false,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getDropdownData = async () => {
  try {
    const { url, method } = CATALOG.getDropdownData();
    const res = await apiClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};
export const getIndustryData = async () => {
  try {
    const { url, method } = CATALOG.getIndustry();
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};
export const getBussinessCategoryData = async () => {
  try {
    const { url, method } = CATALOG.getBussinessCategoryData();
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const getPublicBussinessCategoryData = async () => {
  try {
    const { url, method } = CATALOG.getPublicBussinessCategoryData();
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};
export const getBussinessTypeData = async (sup_cat_id) => {
  try {
    const { url, method } = CATALOG.getBussinessTypeData(sup_cat_id);
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const getCategoryData = async (sup_cat_id) => {
  try {
    const { url, method } = CATALOG.getCategoryData(sup_cat_id);
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const getSubCategoryData = async (cat_id) => {
  try {
    const { url, method } = CATALOG.getSubCategoryData(cat_id);
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};
export const getCostBifurcationsData = async (sup_cat_id) => {
  try {
    const { url, method } = CATALOG.getCostBifurcationsData(sup_cat_id);
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};
export const getEmployeeDetailsData = async (sup_cat_id) => {
  try {
    const { url, method } = CATALOG.getEmployeeDetailsData(sup_cat_id);
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const getLicenseTypeData = async (sup_cat_id) => {
  try {
    const { url, method } = CATALOG.getLicenseType(sup_cat_id);
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const publishCatalog = async (cat_id) => {
  try {
    const { url, method } = CATALOG.publishCatalog(cat_id);
    const res = await apiClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const catalogVerifyOrReject = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOG.catalogVerify();
      const res = await apiClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export default {
  postCatalogueBrandDetails,
  postCatalogueFranchiseDetails,
  postCataloguePagesDetails,
  postCatalogueAdditionalDetails,
  postCataloguePhotosDocslDetails,
  postCatalogueAlternativeContactDetails,
  getCatalogDetails,
  getDropdownData,
  getIndustryData,
  getBussinessCategoryData,
  getBussinessTypeData,
  getPublicBussinessCategoryData,
  getCategoryData,
  getSubCategoryData,
  publishCatalog,
  catalogVerifyOrReject,
  getCostBifurcationsData,
  getEmployeeDetailsData,
  getLicenseTypeData,
};
