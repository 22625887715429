import React, { useState, useEffect } from 'react';
import {
  getCitiesData,
  getCountriesData,
  getStatesData,
} from '../services/miscServices';

const useLocationData = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('IN');
  const [selectedState, setSelectedState] = useState('');

  const fetchCountries = async () => {
    try {
      const response = await getCountriesData();
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchStates = async (countryCode = 'IN') => {
    const params = {
      countryCode: countryCode,
    };
    try {
      const response = await getStatesData(params);
      setStates(response.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };
  const fetchCities = async (countryCode = 'IN') => {
    const params = {
      countryCode: countryCode,
      stateCode: selectedState,
    };

    try {
      const response = await getCitiesData(params);
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };
  // Fetch countries
  useEffect(() => {
    fetchCountries();
  }, []);

  // Fetch states based on selected country
  useEffect(() => {
    fetchStates(selectedCountry);
  }, [selectedCountry]);

  // Fetch cities based on selected state
  useEffect(() => {
    fetchCities(selectedCountry);
  }, [selectedState, selectedCountry]);

  const handleUpdateCountry = async (countryCode = 'IN') => {
    setSelectedCountry(countryCode);
  };

  const handleUpdateState = async (stateCode) => {
    setSelectedState(stateCode);
  };
  return {
    countries,
    states,
    cities,
    handleUpdateCountry,
    handleUpdateState,
  };
};

export default useLocationData;
