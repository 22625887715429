import { apiClient } from '../config/planClient';
import { API_ENDPOINTS } from '../constants/endpoints';
const { PLAN } = API_ENDPOINTS;

export const getPlans = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PLAN.getPlans();
      const res = await apiClient({ url: url, method, params: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getActiveSubscription = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PLAN.getActiveSubscription();
      const res = await apiClient({
        url: url,
        method,
        params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const createManualSubscription = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PLAN.createManualSubscription();
      const res = await apiClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const cancelManualSubscription = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PLAN.cancelManualSubscription();
      const res = await apiClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const renewManualSubscription = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PLAN.renewManualSubscription();
      const res = await apiClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const downloadPaymentdata = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PLAN.downloadPaymentData();
      const res = await apiClient({
        url: url,
        method,
        params,
        responseType: "blob",
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

