import React, { useCallback } from 'react';
import SearchInput from '../SearchInput';
import './DataTable.Style.scss';
import DebounceSearchInput from '../SearchInput/DebounceSearchInput';
import { DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;

const TableHeader = ({
  colsData,
  dataWithKeys,
  rowSelections,
  isAllSelection,
  onSearchInputChange,
  isLoadDebounceSearchInput,
  handleDateChange,
}) => {
  const hasSubHeader = colsData.some((col) => col.subHeader);

  const renderSubHeader = () => {
    return colsData.map((col,i) => {
      return col.subHeader ? (
        col.subHeader.map((subCol, subIndex) => (
          <th key={`${col.dataIndex}-sub-${subIndex}`} {...subCol.props}>
            <div
              style={subCol?.props?.width ? { display: 'flex', flexDirection: 'column', gap: '8px', width: subCol?.props?.width } : { display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <div className='title'>{subCol.title}</div>
            </div>
          </th>
        ))
      ) : (
        <th key={i} style={col.props?.style}></th>
      )
    });
  }
  const renderHeader = useCallback(() => {
    const cols = colsData.map((col) => {

      if (col.component === 'checkbox') {
        return (
          <th key={col.dataIndex} style={{ minWidth: '50px', background: "#EFF5FB" }}>
            <div className='title'>{col.title}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                className='form-check-input'
                type='checkbox'
                checked={isAllSelection}
                onChange={(e) =>
                  rowSelections.onSelectAllRows(dataWithKeys, e.target.checked)
                }
              />
              &nbsp; Select All
            </div>
          </th>
        );
      }
      if (col.dataIndex === 'selection') {
        return (
          <th key={col.dataIndex} style={{ minWidth: '50px' }}>
            {' '}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                className='form-check-input'
                type='checkbox'
                checked={isAllSelection}
                onChange={(e) =>
                  rowSelections.onSelectAllRows(dataWithKeys, e.target.checked)
                }
              />
            </div>
          </th>
        );
      }

      if (col.component === 'inputSearch') {
        return (
          <th key={col.dataIndex} {...col.props} >
            <div
              style={col?.props?.width ? { display: 'flex', flexDirection: 'column', gap: '8px', width: col?.props?.width } : { display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <div className='title'>{col.title}</div>
              {isLoadDebounceSearchInput ? (
                <DebounceSearchInput
                  onChange={(value) =>
                    onSearchInputChange(value, col.dataIndex)
                  }
                  handleWidth={col?.props?.width}
                />
              ) : (
                <SearchInput
                  onChange={(value) =>
                    onSearchInputChange(value, col.dataIndex)
                  }
                />
              )}
            </div>
          </th>
        );
      }

      if (col.component === 'datePicker') {
        return (
          <th key={col.dataIndex} {...col.props}>
            <div
              style={col?.props?.width ? { display: 'flex', flexDirection: 'column', gap: '8px', width: col?.props?.width } : { display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <div className='title'>{col.title}</div>
              {!col?.props?.isRangePicker ? <DatePicker
                onChange={(date, dateString) =>
                  handleDateChange(date, dateString, col.dataIndex)
                }
              /> :
                <RangePicker
                  size='small'
                  onChange={(date, dateString) =>
                    handleDateChange(date, dateString, col.dataIndex)
                  }
                  suffixIcon={<DownOutlined />}
                  separator={<CustomSeparator />}
                />}
            </div>
          </th>

        );
      }
      return (
        <>
          <th key={col.dataIndex} {...col.props} colSpan={col.subHeader ? col.subHeader.length : 1}>
            <div
              style={col?.props?.width ? { display: 'flex', flexDirection: 'column', gap: '8px', width: col?.props?.width } : { display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <div className='title'>{col.title}</div>
            </div>
          </th>
        </>
      );
    });
    return (
      <thead>
        <tr style={{ backgroundColor: '#EFF5FB', position: 'relative' }}>
          {cols}
        </tr>
        {hasSubHeader ?<tr>{renderSubHeader()}</tr>:null}
      </thead>
    );
  }, [
    colsData,
    dataWithKeys,
    rowSelections,
    isAllSelection,
    onSearchInputChange,
    handleDateChange,
  ]);

  return renderHeader();
};

const CustomSeparator = () => (
  <span className="custom-separator">-</span>
);

export default TableHeader;
