import { concat } from 'lodash';
import BrokenImage from '../assets/images/brokenImage.jpg';
import toast from 'react-hot-toast';
import Omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

export const GetAntdOptions = (data, label, value) => {
  if (!label || !value) return [];
  return data.map((option) => {
    return {
      label: option[label],
      value: option[value],
    };
  });
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const getFileExtension = (filename) => {
  return filename.split('.').pop();
};

export const GetYearsOptions = () => {
  const curr_year = new Date().getFullYear();
  return Array.from(new Array(200), (v, i) => (
    <option key={curr_year - i} value={curr_year - i}>
      {curr_year - i}
    </option>
  ));
};

export const GetRattingsOptions = () => {
  return Array.from(new Array(5), (v, i) => (
    <option key={i + 1} value={i + 1}>
      {i + 1}
    </option>
  ));
};
export const getCurrencySymbol = (currency_code = 'INR') => {
  return currency_symbols[currency_code];
};
export function numberWithCommas(number) {
  //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return number.toLocaleString('en-US', { maximumFractionDigits: 2 });
}

export function formatValueInCroresAndLakhs(value) {
  if (value >= 10000000) {
    const crore = value / 10000000;
    return crore + ' cr';
  } else if (value >= 100000) {
    const lakh = value / 100000;
    return lakh + ' lakh';
  } else if (value >= 1000) {
    const thousand = value / 1000;
    return thousand + 'k'; // Format to two decimal places
  } else {
    return value?.toString();
  }
}

export const formatPriceWithCommas = (price) => {
  const currency = getCurrencySymbol('INR');
  return `${currency} ${numberWithCommas(price)}`;
};

export const getKeyValueData = (data) => {
  let options = [];
  if (data && data.length > 0) {
    data.map((el) => {
      options.push({
        _id: el.value,
        name: el.label || el.key,
      });
    });
  }
  return options;
};

/**
 * Converts an array of objects to label-value pairs.
 * @param {Array} data - The array of objects to be converted.
 * @returns {Array} An array of label-value pairs.
 */
export const getLabelValueData = (data) => {
  try {
    // Check if data is valid and not empty
    if (data && data.length) {
      return data.map((el) => ({
        value: el._id || el.id,
        label: el.name,
      }));
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export const getLabelValueOptions = (data) => {
  try {
    if (data && data.length) {
      return data.map((el) => ({
        value: el.id || el._id || el.isoCode,
        label: el.name,
      }));
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export const getLabelValueOptionsByName = (data) => {
  try {
    if (data && data.length) {
      return data.map((el) => ({
        value: el.name,
        label: el.name,
      }));
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
const currency_symbols = {
  INR: '₹',
};

export const getGoogleRattingOptions = () => {
  let googleRattingOptions = [];
  for (let i = 3.5; i <= 5; i += 0.1) {
    let obj = {
      label: i.toFixed(1),
      value: i.toFixed(1),
    };
    googleRattingOptions.push(obj);
  }

  return googleRattingOptions;
};

export const getRoiOptions = () => {
  let options = [];
  for (let i = 6; i <= 42; i += 3) {
    const start = i;
    const end = i + 3;
    let obj = {
      label: `${start}-${end} Months`,
      value: `${start}-${end}`,
    };
    options.push(obj);
  }

  return options;
};

export const isObjectEmpty = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).length === 0;
  }
  return false;
};

export const getValuesWithAllproperty = (data) => {
  return (
    data &&
    data.length &&
    data.map((el) => {
      return {
        ...el,
        value: el.id || el._id || el.isoCode,
        label: el.name,
      };
    })
  );
};

export const getFilteredZonesBasedData = (
  oldArray,
  newArray,
  zoneId,
  isZoneAdded,
  zones
) => {
  const clonedArray = newArray
    ?.filter((vl) => zoneId.includes(vl?.zone_id))
    .map((z) => {
      const obj = {
        ...z,
        value: z?._id,
        label: z?.name,
      };
      if (isZoneAdded) {
        obj['zone'] = zones?.find((zo) => zo?._id === z?.zone_id);
      }
      return obj;
    });
  const uniqueValues = new Set();
  return concat(oldArray, clonedArray).filter((item) => {
    if (!uniqueValues.has(item.value)) {
      uniqueValues.add(item.value);
      return true;
    }
    return false;
  });
};

function removeDecimalAndTrailingZeros(number) {
  const formattedNumber = number.toFixed(2).replace(/\.?0*$/, '');
  return formattedNumber;
}
export function generateNumberOptions(start, end, step) {
  const options = [];
  for (let value = start; value <= end; value += step) {
    const valueInLakh = (value / 100000).toFixed(2);
    const formattedNumber = removeDecimalAndTrailingZeros(
      parseFloat(valueInLakh)
    );

    const label =
      value >= 10000000
        ? `${(value / 10000000).toFixed(2)} crore`
        : `${formattedNumber} lakhs`;
    options.push({ value, label });
  }

  return options;
}

export const findCountryPhoneCode = (countries, countryName) => {
  try {
    const foundCountry = countries.find(
      (country) => country.name === countryName
    );
    if (!foundCountry) {
      throw new Error(`Country with name '${countryName}' not found.`);
    }
    return foundCountry.phoneCode;
  } catch (error) {
    return '';
  }
};

export const findCountryIsoCode = (countries, countryName) => {
  try {
    const foundCountry = countries.find(
      (country) => country.name === countryName
    );
    if (!foundCountry) {
      throw new Error(`Country with name '${countryName}' not found.`);
    }
    return foundCountry.isoCode;
  } catch (error) {
    return '';
  }
};

export const findStateIsoCode = (states, stateName) => {
  try {
    const foundState = states.find((state) => state.name === stateName);
    if (!foundState) {
      throw new Error(`Country with name '${stateName}' not found.`);
    }
    return foundState.isoCode;
  } catch (error) {
    return '';
  }
};

export const flattenObject = (obj, parentKey = '') => {
  let result = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${key}` : key;

    if (typeof value === 'object' && value !== null) {
      result = { ...result, ...flattenObject(value, newKey) };
    } else {
      result[newKey] = value;
    }
  }

  return result;
};

export const printDocumentFromFile = ({ fileExtension, fileType, file }) => {
  if (fileExtension.includes('pdf')) {
    const binaryData = atob(file);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const newWindow = window.open();
    newWindow.document.body.innerHTML = `<iframe src="${blobUrl}" style="width: 100%; height: 100vh;"></iframe>`;
    const iframe = newWindow.document.querySelector('iframe');
    iframe.onload = () => {
      newWindow.focus();
      iframe.contentWindow.print();
    };
  } else {
    const newWindow = window.open('', '_blank');
    newWindow.document.write(
      '<html><head><title>Print Image</title></head><body>'
    );
    newWindow.document.write(
      `<img src="data:image/png;base64,${file}" style="max-width: 100%; height: auto; display: block; margin: 0 auto;">`
    );
    //Wait for 100ms untill image is opened in new window
    setTimeout(() => {
      newWindow.focus();
      newWindow.print();
    }, 100);
  }
};

export const logo_treadmark_status = [
  'Government Processing',
  'Opposition and Responses',
  'Registration Certified',
];

export const getLogoTradmarkStatusOptions = () => {
  return logo_treadmark_status.map((el) => {
    return {
      label: el,
      value: el,
    };
  });
};

export const getDefaultIndiaCountry = (countries) => {
  let country = countries.find((el) => el.name === 'India');
  return [
    {
      label: country?.name,
      value: country?._id,
    },
  ];
};

export const getDefaultBussinessType = (bussinessTypes) => {
  let bussinessType = bussinessTypes.find((el) => el.name === 'Model');
  return {
    label: bussinessType?.name,
    value: bussinessType?._id,
  };
};

export function convertToLowerCaseWithUnderscore(arr) {
  if (!Array.isArray(arr)) {
    return [];
    //throw new Error('Input is not an array');
  }
  return arr.map((item) => {
    if (!item || typeof item !== 'object' || !item.name) {
      throw new Error('Invalid object in the array');
    }
    return item.name.toLowerCase().replace(/ /g, '_');
  });
}

export function convertUnderscoreToTitleCase(inputString) {
  if (typeof inputString !== 'string') {
    throw new Error('Input must be a string');
  }
  // Function logic remains the same
  return inputString
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const getImageUrl = (path) => {
  return `${process.env.REACT_APP_IMAGE_HOST}/${path}`;
};

export const handleBrokenImageError = (e) => {
  e.target.src = BrokenImage;
};

export const getErrorMessage = (error) => {
  // Check for predefined error messages in order
  let errorMessage =
    error?.data?.error?.additional_data?.message?.toString() ||
    error?.data?.error?.err_message?.toString() ||
    'Failed!'; // Default message

  // If error message is still the default, check additional_data object
  const additionalData = error?.data?.error?.additional_data;
  if (
    errorMessage === 'Failed!' &&
    additionalData &&
    typeof additionalData === 'object'
  ) {
    const firstKey = Object.keys(additionalData)[0];
    if (firstKey) {
      errorMessage = additionalData[firstKey]; // Get the first available error message from additional_data
    }
  }

  // Show the error message as a toast
  toast.error(errorMessage);

  return errorMessage;
};

export const isBothEqualObject = (obj1, obj2) => {
  let obj_one_copy = Omit(obj1, 'verificationinfo');
  let obj_two_copy = Omit(obj2, 'verificationinfo');
  return isEqual(obj_one_copy, obj_two_copy);
};

export function getValueInLakhOrCrore(number, unit) {
  let multiplier = 1;

  // Determine the multiplier based on the unit
  if (unit.toLowerCase() === 'lakh') {
    multiplier = 100000;
  } else if (unit.toLowerCase() === 'crore') {
    multiplier = 10000000;
  } else {
    // If the unit is neither lakh nor crore, return null or handle the error accordingly
    return null;
  }

  // Calculate the value and return it
  return number * multiplier;
}

export function checkValueType(value) {
  if (value >= 10000000) {
    return 'crore';
  } else if (value >= 100000) {
    return 'lakh';
  } else {
    return 'lakh';
  }
}

export function getValueInDecimal(value) {
  if (value >= 10000000) {
    // Convert to crores
    return (value / 10000000).toFixed(2); // Up to 2 decimal places for fractional crore
  } else if (value >= 100000) {
    // Convert to lakhs
    return (value / 100000).toFixed(2); // Up to 2 decimal places for fractional lakh
  } else {
    return value; // If less than 1 lakh, return the value as is
  }
}

export function getValueInDecimalFloat(value) {
  if (!value) return '0'; // Handle null or undefined input

  const numericValue = parseFloat(value); // Ensure the input is treated as a float

  if (numericValue >= 10000000) {
    // Convert to crores
    return (numericValue / 10000000).toFixed(2); // Up to 2 decimal places
  } else if (numericValue >= 100000) {
    // Convert to lakhs
    return (numericValue / 100000).toFixed(2); // Up to 2 decimal places
  } else {
    return numericValue.toFixed(2); // Ensure the value retains two decimal places
  }
}
