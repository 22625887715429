import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { catalogReducer } from './catalogReducer';
import { uiReducer } from './uiReducer';
import { userManagement } from './userManagementReducer';
import { ActionTypes } from '../actions/actionTypes';
import storage from 'redux-persist/lib/storage';
import { masterSetupReducer } from './masterSetupReducer';
import { catalogApprovalReducer } from './catalogueApprovalReducers';

const appReducer = combineReducers({
  catalog: catalogReducer,
  auth: authReducer,
  ui: uiReducer,
  userManagement: userManagement,
  masterSetup: masterSetupReducer,
  catalogueApproval: catalogApprovalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ActionTypes.REMOVE_USER) {
    state = undefined;
    storage.removeItem('persist:root-store-crm');
  }
  return appReducer(state, action);
};

export default rootReducer;
