import { DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const rangePresets = [
  {
    label: 'Today',
    value: [dayjs().add(0, 'd'), dayjs()],
  },
  {
    label: 'Yesterday',
    value: [dayjs().add(-1, 'd'), dayjs()],
  },
  {
    label: 'Last Week',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last Quarter',
    value: [dayjs().add(-90, 'd'), dayjs()],
  },
];
const CustomDatePicker = ({ onChangeValues, withToolTip, title,isDefault, ...props }) => {
  const startOfMonth = dayjs().startOf('month');
  const endOfMonth = dayjs().endOf('month');
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      onChangeValues({ fromDate: dateStrings[0], toDate: dateStrings[1] });
    } else {
      onChangeValues({ fromDate: '', toDate: '' });
    }
  };
  const CustomSeparator = () => (
    <span className="custom-separator">-</span>
  );

  return (
    withToolTip ? <Tooltip title={title} placement='bottom' overlayInnerStyle={{ backgroundColor: '#285bd1', color: '#ffffff' }}>
      <RangePicker
        size='large'
        presets={rangePresets}
        onChange={onRangeChange}
        {...props}
        // style={props.width ? { width: props.width } : {}}
        suffixIcon={<DownOutlined />}
        separator={<CustomSeparator />}
      />
    </Tooltip>
      :
      <RangePicker
        size='large'
        presets={rangePresets}
        onChange={onRangeChange}
        {...props}
        // style={props.width ? { width: props.width } : {}}
        suffixIcon={<DownOutlined />}
        separator={<CustomSeparator />}
        defaultValue={isDefault ? [startOfMonth, endOfMonth] : []} // Set the default date range to the current month

      />
  );
};
export default CustomDatePicker;
