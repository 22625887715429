import { ActionTypes } from '../actions/actionTypes';

let initialState = {
  masterCatList: [],
  totalCount: 0,
  faqsMasterCatList: [],
  subCatMainoptions: [],
  mainMasterCatList: [],
  costBifurcationList: [],
};

export const masterSetupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_MASTER_CATEGORYLIST:
      return {
        ...state,
        masterCatList: payload?.categories,
        totalCount: payload?.totalCount,
      };
    case ActionTypes.GET_FAQs_MASTER_CATEGORYLIST:
      return {
        ...state,
        faqsMasterCatList: payload?.categories,
      };
    case ActionTypes.GET_SUB_CATEGORY_MASTER_CATEGORYLIST:
      return {
        ...state,
        subCatMainoptions: payload?.categories,
      };
    case ActionTypes.GET_MAIN_MASTER_CATEGORYLIST:
      return {
        ...state,
        mainMasterCatList: payload?.categories,
      };
    case ActionTypes.GET_MAIN_MASTER_COST_BIFURCATION:
      return {
        ...state,
        costBifurcationList: payload?.data,
      };
    case ActionTypes.GET_ALL_THE_MASTER_CAT_LIST:
      return {
        ...state,
        allMasterCatList: payload,
      };
    default:
      return state;
  }
};
