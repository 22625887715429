import { Outlet, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidenav from '../Sidenav/Sidenav';
import CatalogueSideNave from '../CatalogueSideNave';
import { useSelector, useDispatch } from 'react-redux';

const DashboardLayout = (props) => {
  const ui = useSelector((state) => state.ui.ui);
  const location = useLocation();
  // console.log("ui", ui)

  return (
    <>
      <div className={`container-fluid`}>
        <div className='page'>
          <Header />
          <div className='row'>
            <div
              className={`side-nav-fixed col-md-${ui.toggleSideMenu ? '1' : '2'
                }`}
              style={{
                width: ui.toggleSideMenu ? '80px' : '280px',
                overflow: 'hidden',
              }}
            >
              {location.pathname.includes('manage-catalogue') ? (
                <CatalogueSideNave />
              ) : (
                <Sidenav />
              )}
            </div>
            <div
              className='col-md-12 right-data-container'
              style={{
                paddingLeft: ui.toggleSideMenu ? '85px' : '280px',
                overflow: 'visible',
              }}
            >
              <div className='crm-wrapper'>
                <Outlet />
              </div>
            </div>
          </div>

          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};
export default DashboardLayout;
