import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoutes = ({ allowedRoutes }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const handleRouteChange = () => {
      let currentPath = window.location.pathname;
      const clonedallowedRoutes = allowedRoutes
      const isAllowed = clonedallowedRoutes.some(pattern => {
        const regex = new RegExp(pattern);
        return regex.test(currentPath);
      });
      if(clonedallowedRoutes?.length>0){
        if (!isAllowed) {
            // If the current path is not in the allowedRoutes array, redirect to a default route
            navigate(allowedRoutes?.[0]); // You can change this to any default route
          }
      }
    };

    // Listen for route changes
    const unlisten = window.addEventListener('popstate', handleRouteChange);

    // Check on initial render
    handleRouteChange();

    // Cleanup function
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [allowedRoutes?.length, navigate]);

  return null; // This component doesn't render anything visible
};

export default ProtectedRoutes;
