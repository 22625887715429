import { apiClient } from '../config/client';
import { API_ENDPOINTS } from '../constants/endpoints';
const { AUTH } = API_ENDPOINTS;

export const login = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.login();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const register = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.register();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const changeFirstPassword = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.changeFirstPassword();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export const getBussinessCategory = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.getBussinessCategory();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProfile = async (params) => {
  try {
    const { url, method } = AUTH.getProfile();
    const res = await apiClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};

export const getUserMenu = async () => {
  try {
    const [url, method] = ['/api/v1/menu', 'GET'];
    const res = await apiClient({ url, method });
    return res;
  } catch (error) {}
};

export const forgotPassword = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.forgotPassword();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const resetPassword = (payload, token, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.resetPassword(token, type);
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const refreshToken = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.refreshToken();
      const res = await apiClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export default {
  login,
  register,
  changeFirstPassword,
  getBussinessCategory,
  getProfile,
  getUserMenu,
  resetPassword,
  forgotPassword,
  refreshToken,
};
