import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import DashboardLayout from '../components/Layout/DashboardLayout';
import RequireAuth from '../routes/RequireAuth';
import SuspenseWrapper from './SuspenseWrapper';
import NewsBlogVideosProvider from '../pages/NewsBlogsVideos/AddCategory/NewsBlogVideosProvider';
import CreateInvoice from '../pages/Invoice/CreateInvoice';
import Invoice from '../pages/Invoice/Invoice';
import NotFoundPage from './NotFoundPage';
import CostBifurcation from '../pages/Catalog/AdditionalInfo/CostBifurcation';
import InvestorsInvoices from '../pages/InvestorsInvoices/index';
import BrandInvoices from '../pages/BrandInvoices/Invoice';
import useMenu from '../hooks/useMenu';
import { useAuth } from '../hooks/useAuth';
import { isArray } from 'lodash';
import ProtectedRoutes from './ProtectedRoutes';

const Login = lazy(() => import('../pages/Authentication/Login'));

const BrandList = lazy(() => import('../pages/BrandApproval/BrandList'));
const BrandApproval = lazy(() =>
  import('../pages/BrandApproval/BrandApproval')
);
const BrandDetails = lazy(() => import('../pages/BrandApproval/BrandDetails'));
const ManageCatalogue = lazy(() => import('../pages/Catalog/ManageCatalog'));
const UserManagement = lazy(() => import('../pages/UserManagement'));
const InvestorLeads = lazy(() => import('../pages/InvesterLeads'));
const InvestorPyrList = lazy(() => import('../pages/InvesterLeads/pyrListV2'));

const InvestorLeadDetails = React.lazy(() =>
  import('../pages/InvesterLeads/investorLeadDetails')
);
const ForgotPassword = lazy(() =>
  import('../pages/Authentication/forgotPassword')
);

const UpdatePassword = lazy(() =>
  import('../pages/Authentication/UpdatePassword')
);

const NewsBlogsVideos = lazy(() =>
  import('../pages/NewsBlogsVideos/AddCategory')
);
const AddNewsBlogs = lazy(() => import('../pages/NewsBlogsVideos/News&Blogs'));
const ListNewsBlogVideo = lazy(() =>
  import('../pages/NewsBlogsVideos/ListNewsBlogVideo')
);
const MasterCategory = lazy(() =>
  import('../pages/MasterSetup/MasterCategory/index')
);
const MainCategory = lazy(() =>
  import('../pages/MasterSetup/MainCategory/index')
);
const SubCategory = lazy(() =>
  import('../pages/MasterSetup/SubCategory/index')
);
const BrandCrm = lazy(() => import('../pages/BrandCrm'));
const ListTemplateTable = lazy(() =>
  import('../pages/CreateTemplate/ListTemplateTable.js/index')
);
const AddEditTemplate = lazy(() =>
  import('../pages/CreateTemplate/AddEditTemplate')
);
const CostBifurcations = lazy(() =>
  import('../pages/MasterSetup/CostBifurcation')
);
const BusinessType = lazy(() => import('../pages/MasterSetup/BusinessType'));
const EmployeeInfo = lazy(() => import('../pages/MasterSetup/EmployeeInfo'));
const LicenseType = lazy(() => import('../pages/MasterSetup/LicenseType'));
const InvesterPYR = lazy(() => import('../pages/InvesterPYR'));
const UserPermission = lazy(() => import('../pages/UserPermission'));
const Settings = lazy(() => import('../pages/Settings'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const NewsLetter = lazy(() => import('../pages/NewsLetter'));
const UploadImages = lazy(() =>
  import('../pages/NewsBlogsVideos/UploadImages')
);
const InvestorPYRLeads = lazy(() => import('../pages/InvestorPYRLeads'));
const InvestorReports = lazy(() => import('../pages/Reports/InvestorReports'));
const BrandReports = lazy(() => import('../pages/Reports/BrandReports'));

const PageRoutes = () => {
  const { menu } = useMenu();
  const navigate = useNavigate();
  const auth = useAuth();
  const localStorageCheck = Boolean(localStorage.getItem('isUrlSet'));
  const getTheUrlsForMenu = useMemo(() => {
    const urlsAccess = [];
    if (isArray(menu)) {
      menu?.map((vl) => {
        if (vl?.path) {
          urlsAccess.push(vl?.path);
        }
        if (
          [
            '/investor-crm',
            '/investor-pyr',
            '/catalogue-approval',
            '/brands',
          ].includes(vl?.path)
        ) {
          if (vl.path === '') {
            urlsAccess.push('^/investor-crm/crm-list/\\w+$');
          }
          if (vl.path === '/investor-pyr') {
            urlsAccess.push('^/investor-pyr/pyr-list/\\w+$');
          }
          if (vl.path === '/catalogue-approval') {
            urlsAccess.push('^/manage-catalogue/\\w+$');
          }
          if (vl.path === '/brands') {
            urlsAccess.push('/invoices/create-invoice');
          }
        }

        if (vl?.sub_menu?.length > 0) {
          vl?.sub_menu?.map((subVl) => {
            urlsAccess.push(subVl?.path);
          });
        }
      });
    }
    return urlsAccess;
  }, [menu?.length]);
  useEffect(() => {
    if (auth?.user?._id && menu?.length > 0 && !localStorageCheck) {
      localStorage.setItem('isUrlSet', true);
      navigate(menu?.[0]?.path);
    }
  }, [auth?.user, menu?.length]);
  return (
    <>
      {auth?.user && <ProtectedRoutes allowedRoutes={getTheUrlsForMenu} />}
      <Routes>
        <Route
          path={ROUTES.LOGIN}
          element={
            <SuspenseWrapper>
              <Login />
            </SuspenseWrapper>
          }
        />
        <Route
          path={ROUTES.FORGOTPASSWORD}
          element={
            <SuspenseWrapper>
              <ForgotPassword />
            </SuspenseWrapper>
          }
        />
        <Route
          path={ROUTES.RESET_PASSWORD}
          element={
            <SuspenseWrapper>
              <UpdatePassword />
            </SuspenseWrapper>
          }
        />
        <Route
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route
            path='/'
            element={
              <SuspenseWrapper>
                <BrandList />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/brands'
            element={
              <SuspenseWrapper>
                <BrandList />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/brands/brand-list/:brandId'
            element={
              <SuspenseWrapper>
                <BrandDetails />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/catalogue-approval'
            element={
              <SuspenseWrapper>
                <BrandApproval />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/manage-catalogue/:brandUserId'
            element={
              <SuspenseWrapper>
                <ManageCatalogue />
              </SuspenseWrapper>
            }
          />

          <Route
            path='/users'
            element={
              <SuspenseWrapper>
                <UserManagement />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/investor-crm'
            element={
              <SuspenseWrapper>
                <InvestorLeads />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/investor-pyr'
            element={
              <SuspenseWrapper>
                <InvesterPYR />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/investor-pyr/pyr-list/:id'
            element={
              <SuspenseWrapper>
                <InvestorPyrList moduleType='pyr' />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/investor-crm/crm-list/:id'
            element={
              <SuspenseWrapper>
                <InvestorPyrList moduleType='crm' />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/investor-lead-details/:id'
            element={
              <SuspenseWrapper>
                <InvestorLeadDetails />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/add-category'
            element={
              <SuspenseWrapper>
                <NewsBlogVideosProvider>
                  <NewsBlogsVideos />
                </NewsBlogVideosProvider>
              </SuspenseWrapper>
            }
          />
          <Route
            path='/add-news-blog-video'
            element={
              <SuspenseWrapper>
                <AddNewsBlogs />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/list-news-blog-video'
            element={
              <SuspenseWrapper>
                <ListNewsBlogVideo />
              </SuspenseWrapper>
            }
          />

          <Route
            path={'/invoices'}
            element={
              <SuspenseWrapper>
                <Invoice />
              </SuspenseWrapper>
            }
          />

          <Route
            path={'/investor-invoices'}
            element={
              <SuspenseWrapper>
                <InvestorsInvoices />
              </SuspenseWrapper>
            }
          />
          <Route
            path={'/brand-invoices'}
            element={
              <SuspenseWrapper>
                <BrandInvoices />
              </SuspenseWrapper>
            }
          />
          <Route
            path={'/invoices/create-invoice'}
            element={
              <SuspenseWrapper>
                <CreateInvoice />
              </SuspenseWrapper>
            }
          />
          <Route
            path={'/master-category/:id?'}
            element={
              <SuspenseWrapper>
                <MasterCategory />
              </SuspenseWrapper>
            }
          />
          <Route
            path={'/main-category/:id?'}
            element={
              <SuspenseWrapper>
                <MainCategory />
              </SuspenseWrapper>
            }
          />
          <Route
            path={'/sub-category/:id?'}
            element={
              <SuspenseWrapper>
                <SubCategory />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/brand-crm'
            element={
              <SuspenseWrapper>
                <BrandCrm />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/template-lists'
            element={
              <SuspenseWrapper>
                <ListTemplateTable />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/edit-template'
            element={
              <SuspenseWrapper>
                <AddEditTemplate />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/cost-bifurcation/:id?'
            element={
              <SuspenseWrapper>
                <CostBifurcations />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/business-type/:id?'
            element={
              <SuspenseWrapper>
                <BusinessType />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/employee-management/:id?'
            element={
              <SuspenseWrapper>
                <EmployeeInfo />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/license-type/:id?'
            element={
              <SuspenseWrapper>
                <LicenseType />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/user-permission'
            element={
              <SuspenseWrapper>
                <UserPermission />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/settings'
            element={
              <SuspenseWrapper>
                <Settings />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/dashboard'
            element={
              <SuspenseWrapper>
                <Dashboard />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/newsletter'
            element={
              <SuspenseWrapper>
                <NewsLetter />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/upload-image'
            element={
              <SuspenseWrapper>
                <UploadImages />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/investor-buyleads'
            element={
              <SuspenseWrapper>
                <InvestorPYRLeads />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/investor-reports'
            element={
              <SuspenseWrapper>
                <InvestorReports />
              </SuspenseWrapper>
            }
          />
          <Route
            path='/brand-reports'
            element={
              <SuspenseWrapper>
                <BrandReports />
              </SuspenseWrapper>
            }
          />

          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default PageRoutes;
