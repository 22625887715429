export const AUTH_TOKEN_KEY = 'crm_token';
export const AUTH_REFRESH_TOKEN_KEY = 'refresh_token';

export const businessCategoryOptions = [
  {
    label: 'Food & Beverages',
    value: 'Food & Beverages',
  },
  {
    label: 'Retail Shop',
    value: 'Retail Shop',
  },
  {
    label: 'Logistic',
    value: 'Logistic',
  },
  {
    label: 'Healthcare',
    value: 'Healthcare',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Tours & Leisure',
    value: 'Tours & Leisure',
  },
];
