import { useState, useEffect } from 'react';
import * as api from '../services/authServices';
import { AiOutlineAppstore, AiOutlineUser } from 'react-icons/ai';
import {
  IoSettingsOutline,
  IoAnalytics,
  IoSettings,
  IoNewspaperSharp,
} from 'react-icons/io5';
import { BiSolidCategory } from 'react-icons/bi';
import { FaTableCells } from 'react-icons/fa6';
import { useAuth } from '../hooks/useAuth';
import { concat } from 'lodash';

const useMenu = () => {
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  useEffect(() => {
    const fetchMenu = async () => {
      try {
        setLoading(true);
        const response = await api.getUserMenu();

        if (response.status === 'Success') {
          let sidebarMenu = response.data;
          sidebarMenu = concat(sidebarMenu, []);
          // sidebarMenu = sidebarMenu.map((vl) => {
          //   if (vl.name === "News, Blog & Video") {
          //     vl.sub_menu.push({
          //       "name": "Upload Images",
          //       "department": {
          //         "020": [
          //           "Operation"
          //         ],
          //         "030": [
          //           "Operation"
          //         ]
          //       },
          //       "path": "/upload-images",
          //       "order": 3,
          //       "icon": "table"
          //     })
          //   }
          //   return vl
          // })
          sidebarMenu = sidebarMenu.map((vl) => {
            if (vl.name === 'News, Blog & Video') {
              vl.path = '/add-category';
              // vl.path = '';
            }
            if (vl.name === 'Master & Set Up') {
              vl.path = '/master-category';
              // vl.path = '';
            }
            if (vl.name === 'Create Template') {
              vl.path = '/edit-template';
              // vl.path = '';
            }
            if (vl.name === 'Accounts') {
              vl.path = '/brand-invoices';
              // vl.path = '';
            }
            if (vl.name === 'Reports') {
              vl.path = '/investor-reports';
              // vl.path = '';
            }
            return vl;
          });
          setMenu(sidebarMenu);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (auth?.user?._id) fetchMenu();
  }, [auth?.user]);

  return { menu, loading, setMenu };
};

export default useMenu;

export const iconMapping = {
  brand: <AiOutlineAppstore />,
  users: <AiOutlineUser />,
  permission: <AiOutlineUser />,
  report: <IoAnalytics />,
  settings: <IoSettings />,
  category: <BiSolidCategory />,
  news: <IoNewspaperSharp />,
  table: <FaTableCells />,
  dashboard: <AiOutlineAppstore />,
};
