import React, { useState, useCallback, useEffect } from 'react';
import InvoiceForm from './InvoiceForm';
import PreviewInvoice from './PreviewInvoice';

import './invoice.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import * as API from '../../services/invoiceService';
import toast from 'react-hot-toast';

const CreateInvoice = () => {
  let initFormValues = {
    invoice_category: 'subscription',
    invoice_type: 'draft',
    proforma: false,
    name: null,
    email: null,
    countrycode: '+91',
    mobile: null,
    company_name: null,
    gst: null,
    address: null,
    country: {
      disabled: undefined,
      key: "643a7e561c11a74338a882bf",
      label: "India",
      title:undefined,
      value: "643a7e561c11a74338a882bf",
    },
    state: undefined,
    city: undefined,
    pincode: null,
    discount: null,
    subtotal: null,
    tds: null,
    cgst: 0,
    sgst: 0,
    igst: 18,
    plan_price: null,
    price_paid: null,
    total_amount_paid: null,
    balance_amount: null,
    user_id: null,
    plan_id: undefined,
    title: null,
    is_manual: false,
    is_free: false,
    invoice_number: 'INV-XXXXX',
  };
  const [formValues, setFormValues] = useState(initFormValues);
  const [formErrors, setFormErrors] = useState({});
  const [amountBreakup, setAmountBreakup] = useState({
    totalAmount: null,
    tdsAmount: null,
    igstAmount: null,
    discountAmount: null,
    cgstAmount: null,
    sgstAmount: null,
  });
  const [showPreview, setShowPreview] = useState(false);
  const [countryCode, setCountryCode] = useState('+91');
  const [loading, setLoading] = useState(false);

  const {
    invoice_category,
    invoice_type,
    proforma,
    name,
    email,
    mobile,
    company_name,
    gst,
    address,
    country,
    city,
    pincode,
    discount,
    subtotal,
    tds,
    cgst,
    sgst,
    igst,
    plan_price,
    price_paid,
    total_amount_paid,
    balance_amount,
    user_id,
    plan_id,
    title,
    is_manual,
    is_free,
  } = formValues;
  let { state } = useLocation();
  let navigate = useNavigate();

  //console.log('state', state);
  // console.log('formValues', formValues);
  useEffect(() => {
    // console.log('state', state);
    if (state?.details) {
      setFormValues({
        ...formValues,
        company_name: state?.details?.companyname || null,
        name: state?.details?.natureofbusiness?.ceoname || null,
        gst: state?.details?.gstin || null,
        plan_id: {
          value: state?.subscription?.plan?._id || undefined,
          label: state?.subscription?.plan?.name || undefined,
        },
        plan_price: state?.plan?.price || undefined,
        subtotal: state?.plan?.price || null,
        is_manual: state?.subscription?.is_manual || false,
        is_free: state?.subscription?.is_free || false,
        proforma: state?.proforma || false,
        user_id: state?.subscription?.user_id || null,
        // email: 'rk@gmail.com',
        // mobile: '9937723163',
        // address: 'KIIT Rd, Patia, Bhubaneswar, Odisha 751024',
        // pincode: '751024',
      });
    }
  }, [state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  };

  const handleSelectChange = (value, name) => {
    console.log(name, value);
    let formValuesObj = {
      ...formValues,
      [name]: value,
    };
    if (name == 'state') {
      if (value?.label == 'Maharashtra') {
        formValuesObj = {
          ...formValues,
          [name]: value,
          cgst: 9,
          sgst: 9,
          igst: 0,
        };
      } else {
        formValuesObj = {
          ...formValues,
          [name]: value,
          cgst: 0,
          sgst: 0,
          igst: 18,
        };
      }
    }
    setFormValues(formValuesObj);
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleEditPreview = () => {
    setShowPreview(false);
  };

  useEffect(() => {
    handleCalculateTotalAmount();
  }, [subtotal, tds, discount, formValues?.state]);

  const handleCalculateTotalAmount = () => {
    const discountPercentage = parseFloat(discount);
    const subtotalAmount = parseFloat(subtotal);
    const tdsPercentage = parseFloat(tds);
    let discountAmount = 0;
    let originalValueAfterDiscount = isNaN(subtotalAmount) ? 0 : subtotalAmount;
    // Check if discountPercentage is provided
    if (!isNaN(discountPercentage)) {
      // Calculate discount amount
      discountAmount = (discountPercentage / 100) * originalValueAfterDiscount;

      // Calculate original value after discount
      originalValueAfterDiscount -= discountAmount;
    }
    // Calculate IGST
    const igst = (18 / 100) * originalValueAfterDiscount;
    let tdsAmount = 0;
    // Check if tdsPercentage is provided
    if (!isNaN(tdsPercentage)) {
      // Calculate TDS amount
      tdsAmount = (tdsPercentage / 100) * originalValueAfterDiscount;
    }
    // Calculate total amount
    const totalAmount = originalValueAfterDiscount + igst - tdsAmount;

    let cgstAmount = 0;
    let sgstAmount = 0;
    if (formValues?.state?.label == 'Maharashtra') {
      cgstAmount = (9 / 100) * originalValueAfterDiscount;
      sgstAmount = (9 / 100) * originalValueAfterDiscount;
    }
    setAmountBreakup({
      ...amountBreakup,
      totalAmount: totalAmount,
      tdsAmount: tdsAmount,
      cgstAmount,
      sgstAmount,
      igstAmount: state?.label == 'Maharashtra' ? 0 : igst,
      discountAmount: discountAmount,
    });
  };

  const getPayload = (invoice_type = 'generated') => {
    let payload = {
      invoice_category: is_free ? 'service_charge' : 'subscription',
      invoice_type: invoice_type,
      proforma: proforma,
      user_info: {
        name: name,
        email: email,
        countrycode: countryCode,
        mobile: mobile,
        company_name: company_name,
        gst: gst,
        address: address,
        country: {
          name: country?.label,
          _id: country?.value,
        },
        state: {
          name: formValues?.state?.label,
          _id: formValues?.state?.value,
        },
        city: {
          name: city?.label,
          _id: city?.value,
        },
        pincode: pincode * 1,
      },
      discount: discount * 1,
      tax_info: {
        tds: tds * 1,
        cgst: cgst * 1,
        sgst: sgst * 1,
        igst: igst * 1,
      },
      plan_price: plan_price * 1,
      price_paid: plan_price * 1,
      total_amount_paid: amountBreakup?.totalAmount,
      balance_amount: balance_amount * 1,
      user_id: user_id,
      plan_id: is_free ? undefined : plan_id?.value,
      title: title,
    };

    return payload;
  };

  const createInvoice = async (invoice_type = 'generated') => {
    let payload = getPayload(invoice_type);
    console.log('payload dd', payload);
    setLoading(true);
    try {
      const response = await API.createInvoice(payload);
      console.log('res', response);
      if (response.status === 'Success') {
        toast.success(response?.message);

        setTimeout(() => {
          navigate('/brand-invoices');
        }, [1000]);
        //redirect to listing page
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error('Error!');
    } finally {
      setLoading(false);
    }
  };

  //console.log('formValues', formValues);
  return (
    <div className='invoice__page'>
      <div className='container-fluid'>
        <h1 className='header__title'>
          {proforma ? 'Proforma Invoice' : 'Invoice'}
        </h1>
        {showPreview ? (
          <PreviewInvoice
            formValues={{
              ...formValues,
              ...amountBreakup,
            }}
            handleEditPreview={handleEditPreview}
            createInvoice={createInvoice}
            loading={loading}
          />
        ) : (
          <InvoiceForm
            formValues={formValues}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            totalAmount={amountBreakup?.totalAmount}
            handlePreview={handlePreview}
            createInvoice={createInvoice}
            setCountryCode={setCountryCode}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default CreateInvoice;
