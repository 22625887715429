import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import { useFetch } from '../../hooks/CustomHooks';
// import urls from '../../config/Config';
import { AiOutlineAppstore, AiOutlineUser } from 'react-icons/ai';
import { IoSettingsOutline } from 'react-icons/io5';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { toggleSidenav } from '../../redux/actions/uiActions';
import './sidemenu.style.scss';
import useMenu, { iconMapping } from '../../hooks/useMenu';
import SpinnerSmall from '../Loader/SpinnerSmall';
import { concat, isArray } from 'lodash';
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs';
import { RightOutlined, DownOutlined } from '@ant-design/icons'
const Sidenav = (props) => {
  const { menu, loading, setMenu } = useMenu();
  const newMenu = menu;
  // const [navs] = useFetch(urls.urls.sideNav);
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui.ui);
  const navs = [
    {
      label: 'Brand List',
      icon: <AiOutlineAppstore />,
      link: '/brand-list',
    },
    {
      label: 'User Management',
      icon: <AiOutlineUser />,
      link: '/user-management',
    },
    {
      label: 'Investor Leads',
      icon: <AiOutlineUser />,
      link: '/investor-leads',
    },
    {
      label: 'Catalogue Approval',
      icon: <AiOutlineAppstore />,
      link: '/catalogue-approval',
    },
    {
      label: 'Settings',
      icon: <IoSettingsOutline />,
      link: '/settings',
    },
  ];

  const processMenuItems = () => {
    if (!menu?.length) return [];
    return newMenu?.map((item, i) => {
      return {
        label: item.name,
        link: item.path,
        icon: iconMapping[item.icon] || <AiOutlineUser />,
        isParent: item?.sub_menu?.length > 0,
        subMenu: item?.sub_menu?.map((subs) => {
          return {
            label: subs.name,
            link: subs.path,
            icon: iconMapping[subs.icon] || <AiOutlineUser />,
          };
        }),
        isActiveSubmenu: item?.isActiveSubmenu,
      };
    });
  };

  const handleTogle = () => {
    dispatch(toggleSidenav());
  };

  if (loading) {
    return (
      <div className='pt-4 d-flex justify-content-center align-items-center h-100'>
        {' '}
        <SpinnerSmall />
      </div>
    );
  }
  const toggleSubMenu = (index) => {
    let cloneMenu = newMenu;
    cloneMenu[index].isActiveSubmenu = !cloneMenu[index].isActiveSubmenu;
    setMenu(cloneMenu);
  };
  return (
    <div className='side-nav'>
      <button className='toggle-switch' onClick={handleTogle}>
        {ui.toggleSideMenu ? (
          <BsArrowRightCircle size={22} />
        ) : (
          <BsArrowLeftCircle size={22} />
        )}
      </button>

      <ul className={`side-menu ${ui.toggleSideMenu ? 'text-center' : ''}`}>
        {processMenuItems()?.map((item, i) => {
          const isacitve = item.link === window.location.pathname
          return (
            <li key={i}>
              <div>
                <NavLink
                  to={item?.isParent ? window.location.pathname : item.link}
                  // to={item.link}
                  onClick={() =>
                    isArray(item?.subMenu) && item?.subMenu?.length > 0
                      ? toggleSubMenu(i)
                      : ''
                  }
                  // isActive={() => item?.isActiveSubmenu}
                  className={({ isActive }) =>
                    isActive && (isacitve || item?.isActiveSubmenu) ? "active" : ""
                  }
                >
                  <span className='icons'>{item.icon}</span>
                  {!ui.toggleSideMenu ? <>{item.label}</> : <></>}
                  {!ui.toggleSideMenu ? <div style={{ float: 'inline-end' }}>
                    {Array.isArray(item?.subMenu) && item?.subMenu?.length > 0 ? item?.isActiveSubmenu ? <DownOutlined /> : <RightOutlined /> : null}
                  </div> : <></>}
                </NavLink>
              </div>
              <ul className='sub-nav-ul'>
                {item?.isActiveSubmenu &&
                  item?.subMenu?.map((subItem) => {
                    return (
                      <li>
                        <NavLink to={subItem.link}>
                          <span className='icons'>{subItem.icon}</span>
                          {!ui.toggleSideMenu ? <>{subItem.label}</> : <></>}
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            </li>
          );
        })}
        {/* 
        <li>
          <NavLink to='/invoices'>
            <span className='icons'>
              <AiOutlineAppstore />
            </span>
            Invoices
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidenav;
