import React, { Suspense } from 'react';
import LazyLoad from './LazyLoad';

const SuspenseWrapper = (props) => {
  //   const LazyComponent = React.lazy(() => import(`../${props.path}`));
  return (
    <Suspense fallback={<LazyLoad />}>
      {/* <LazyComponent /> */}
      {props.children}
    </Suspense>
  );
};

export default SuspenseWrapper;
