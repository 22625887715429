import { Select } from 'antd';
import './CustomSelect.Style.scss';
import { forwardRef, useMemo } from 'react';

const deduplicateOptions = (options) => {
  const seen = new Set();
  return options?.filter(option => {
    const duplicate = seen.has(option.value);
    seen.add(option.value);
    return !duplicate;
  });
};

const CustomMultiSelect = forwardRef(function CustomMultiSelect(
  {
    options = [],
    defaultValue = [],
    placeholder = 'Please select',
    register,
    mode = 'multiple',
    handleOnHover,
    handleOnBlur,
    isExpanded,
    isOption = false,
    ...rest
  },
  ref
) {
  const deduplicatedOptionss = useMemo(() => {
    if (options) {
      return deduplicateOptions(options)
    }
    return []

  }, [options]);
  return (
    <Select
      mode={mode}
      className={`select-antd-wrapper  ${isExpanded ? 'select-antd-input expanded' : ''}`}
      allowClear={mode === 'multiple' || mode === 'tags'}
      //allowClear={true}
      style={{
        width: '100%',
      }}
      placeholder={placeholder}
      options={!isOption ? options : deduplicatedOptionss}
      {...rest}
      ref={ref}
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      dropdownStyle={{
        //zIndex: 1072,
        zIndex: 1200,
      }}
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOnBlur}
    //maxTagCount={5}
    />
  );
});

export default CustomMultiSelect;