import { defaultAxios } from './config/brandClient';
import { logout } from './redux/actions/authActions';
import store from './redux/store';
import PageRoutes from './routes/PageRoutes';
import nProgress from 'nprogress';
import ToastComponent from './components/Toast/ToastComponent';
import 'antd/dist/reset.css';

function App() {
  nProgress.configure({ showSpinner: false });

  return (
    <div className='App'>
      <PageRoutes />
      <ToastComponent />
    </div>
  );
}

/** Intercept any unauthorized request.
 * dispatch logout action accordingly **/
// const UNAUTHORIZED = 401;
// const { dispatch } = store; // direct access to redux store.
// defaultAxios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { status } = error.response;
//     if (status === UNAUTHORIZED) {
//       dispatch(logout());
//     }
//     return Promise.reject(error);
//   }
// );

export default App;
