import React from 'react';
import './Search.Style.scss';
import { debounce } from 'lodash';
import { IoIosSearch } from 'react-icons/io';

function DebounceSearchInput({ onChange, handleWidth }) {
  const onChanges = (value) => {
    debounceFn(value);
  };
  const debounceFn = debounce((value) => {
    onChange(value);
  }, 500);

  return (
    <div className='search-container' style={handleWidth ? { width: handleWidth } : {width:"150px"}}>
      {/* <i className='search-icon  bi bi-search'></i> */}
      <IoIosSearch className='search-icon' size={20} />
      <input
        type='text'
        className='search-input'
        placeholder='Search...'
        // value={value}
        onChange={(e) => onChanges(e.target.value)}
      />
    </div>
  );
}

export default React.memo(DebounceSearchInput);
