import { API_ENDPOINTS } from '../constants/endpoints';
import { apiClient as masterClient } from '../config/masterManagementClient.js';

export const getParentCategory = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = API_ENDPOINTS.CATEGORY.getParentCategory();
      const res = await masterClient({ url, params, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCategoryList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = API_ENDPOINTS.CATEGORY.getCategoryList();
      const res = await masterClient({ url, params, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const addCategory = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = API_ENDPOINTS.CATEGORY.addCategory();
      const res = await masterClient({ url, data: params, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteCategory = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = API_ENDPOINTS.CATEGORY.deleteCategory(id);
      const res = await masterClient({ url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCategoryById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = API_ENDPOINTS.CATEGORY.getCategoryById(id);
      const res = await masterClient({ url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateCategory = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = API_ENDPOINTS.CATEGORY.updateCategory(
        params?._id
      );
      const res = await masterClient({ url, data: params, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
