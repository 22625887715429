import axios from 'axios';
import { AUTH_TOKEN_KEY } from '../constants/constant';
import { getParsedItem } from '../utils/Storage';
import { createAxiosInstance } from './axiosInstances';
import { toast } from 'react-hot-toast';

const BASE_URL = process.env.REACT_APP_BRAND_URL;

const defaultHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Content-Type': 'application/json',
};

// export const defaultAxios = axios.create({
//   baseURL: BASE_URL,
// });

export function apiClient({
  url,
  data = {},
  method = 'GET',
  headers = {},
  noHeaders,
  showError = true,
  ...rest
}) {
  const defaultAxios = createAxiosInstance(BASE_URL);
  const token = getParsedItem(AUTH_TOKEN_KEY);

  return new Promise((resolve, reject) => {
    defaultAxios({
      method,
      url,
      headers: {
        ...(noHeaders ? {} : defaultHeaders),
        ...headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      data,
      ...rest,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        if (!showError) {
          reject(err?.response || err);
          return;
        }
        let errorMessage = '';
        const additionalData = err?.response?.data?.error?.additional_data;
        if (additionalData && typeof additionalData === 'object') {
          const firstKey = Object.keys(additionalData)[0];
          if (firstKey) {
            errorMessage = additionalData[firstKey];
          }
        }
        errorMessage =
          errorMessage ||
          err?.response?.data?.error?.err_message ||
          err?.response?.data?.err_message ||
          'Failed';

        toast.error(errorMessage);
        if (err?.response?.status === 401) {
        }
        reject(err?.response || err);
      });
  });
}
