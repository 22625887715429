import moment from 'moment';

export const formatDate = (date, dateFormat = 'DD/MM/YYYY') => {
  if (typeof date === 'number') {
    // Assuming the number is a Unix timestamp in seconds
    return moment.unix(date).format(dateFormat);
  } else if (moment(date).isValid()) {
    // Assuming the input is a valid date string
    return moment(date).format(dateFormat);
  } else {
    // Handle other cases (e.g., invalid input)
    return 'Invalid Date';
  }
};
