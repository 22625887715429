import React from 'react';
import { useCallback, useState, useEffect, useMemo } from 'react';
import './DataTable.Style.scss';
import TableHeader from './TableHeader';
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'antd'

/**
 * Renders a DataTable component with the provided columns and data.
 *
 * @param {Array} columns - An array of objects representing the columns of the table.
 * @param {Array} data - An array of objects representing the data to be displayed in the table.
 * @param {function} rowActions - A function that returns the actions for each row in the table.
 * @param {Array} rowSelections - An array of objects representing the selected rows in the table.
 * @param {boolean} showSelection - Determines whether to show the selection column in the table.
 * @param {number} currentPage - The current page number in the pagination.
 * @param {number} pageSize - The number of items to display per page in the pagination.
 * @param {function} onRowClick - A function that is called when a row is clicked.
 * @param {boolean} loading - Determines whether to show the loading state of the table.
 * @param {boolean} showHeader - Determines whether to show the Header of the table.
 * @param {function} onSearchInputChange - A function that is called when the search input value changes.
 * @param {...props} props - Additional props to be passed to the component.
 * @return {JSX.Element} The rendered DataTable component.
 */
function DataTable({
  columns = [],
  data = [],
  rowActions,
  rowSelections = [],
  showSelection = false,
  currentPage,
  pageSize,
  onRowClick,
  loading = false,
  showHeader = true,
  onSearchInputChange,
  isLoadDebounceSearchInput,
  handleDateChange,
  tableWidth,
  tableHeight,
  isRowPointer = false,
  selectedRole,
  handleCheckbox,
  assigneeData,
  showAssigneeName,
  showAssignee,
  assigneeTitle,
  ...props
}) {
  const [colsData, setColumns] = useState(columns);

  // This is usefull when we need client side Pagination
  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * pageSize;
  //   let lastPageIndex = firstPageIndex + pageSize;
  //   if (lastPageIndex > data.length) {
  //     lastPageIndex = data.length;
  //   }
  //   return data.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage, pageSize, data]);

  const currentTableData = data;

  const dataWithKeys = useMemo(
    () =>
      currentTableData?.map((el, i) => {
        const o = Object.assign({}, el);
        o.key_id = `${o[rowSelections.uniqueId]}`;
        return o;
      }),
    [currentTableData, rowSelections]
  );
  const isAllSelection = useMemo(() => {
    if (!!!rowSelections?.selectedRows?.length) return false;
    const isAllSelected = dataWithKeys.every((data) => {
      return rowSelections.selectedRows.some(
        (item) => item.key_id === data.key_id
      );
    });
    return isAllSelected;
  }, [dataWithKeys, rowSelections.selectedRows]);
  useEffect(() => {
    if (showSelection) {
      setColumns([{ dataIndex: 'selection' }, ...columns]);
    }
  }, [showSelection, columns]);
  const checkBox = useCallback(
    (record, col) => {
      return (
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              className='form-check-input'
              type='checkbox'
              name='checkbox'
              checked={rowSelections?.selectedRows?.some(
                (item) => item.key_id === record.key_id
              )}
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                e.stopPropagation();
                rowSelections.onSelectRow(record, e.target.checked);
              }}
            />
          </div>
        </td>
      );
    },
    [rowSelections]
  );


  const renderRows = useCallback(() => {
    const rows = dataWithKeys?.map((record, i) => {
      const departMentRoleData = record?.department?.[selectedRole]
      const serialNumber = (currentPage - 1) * pageSize + i + 1;

      const mainRow = (
        <tr
          key={record.key_id}
          onClick={(e) => {
            if (e.target?.name === 'checkbox') return;
            // onRowClick(record);
          }}
          className='pointer-cursor'
          style={isRowPointer ? { position: 'relative', cursor: "pointer" } : { position: 'relative' }}
        >
          {colsData.map((col, colIndex) => {
            if (col.component === 'subHead' && col.subHeader) {  // Loop through subHeader for checkbox
              return (
                col.subHeader.map((subCol, subIndex) => (
                  <td key={subIndex} {...col.props}>
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={departMentRoleData?.includes(subCol.departmentName) || false}
                      onChange={(e) => {
                        handleCheckbox(e.target.checked, i, subCol?.departmentName)
                      }}
                      style={subCol.props.style}
                    />
                  </td>
                ))
              );
            }
            if (col.dataIndex === 'selection' || col.component === 'checkbox') {
              return checkBox(record, col);
            }
            if (col.dataIndex === 'actions') {
              if (!rowActions) return <td></td>;
              return <td {...col.props}>{rowActions(record, col)}</td>;
            }
            if (col.dataIndex === 'rowIndex') {

              return <td {...col.props}>{`${serialNumber}.`}</td>;
            }
            return (
              <td
                {...col.props}
                style={col?.props?.isApplyBackGrundHeader ? { ...col?.props?.style, background: 'none' } : { ...col?.props?.style }}
                onClick={(e) => {
                  if (
                    e.target?.name === 'checkbox' ||
                    col?.preventRowClick === true
                  )
                    return;
                  onRowClick(record);
                }}
              >
                {col?.props?.showTooltipOnColumnVal && assigneeData?.[col?.props?.toolTipValueKeyId] !== record?._id? <span onClick={() => showAssigneeName(record)}>{col?.render ? col.render(record, col) : record[col.dataIndex]}</span> : assigneeData && col?.props?.showTooltipOnColumnVal && assigneeData?.[col?.props?.toolTipValueKeyId] === record?._id? <Tooltip title={assigneeTitle}>
                  {col?.render ? col.render(record, col) : record[col.dataIndex]}
                </Tooltip> :
                  col?.render ? col.render(record, col) : record[col.dataIndex]}

                {col?.props?.showAssigneeIconInColumn ? assigneeData?.assigneeName && assigneeData._id === record?._id ? (
                  <Tooltip title={assigneeData.assigneeName}>
                    <FaInfoCircle
                      color="#285bd1"
                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                    />
                  </Tooltip>
                ) : (
                  showAssignee ? <FaInfoCircle
                    color="#285bd1"
                    onClick={() => showAssigneeName(record)}
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                  /> : null
                ) : null}
              </td>
            );
          })}
        </tr>
      );

      const subMenuRows = record?.sub_menu?.map((subItem, subIndex) => {
        const departmentDataSubMenu = subItem?.department?.[selectedRole]
        return <tr
          key={`${record.key_id}-${subIndex}`}
          onClick={(e) => {
            if (e.target?.name === 'checkbox') return;
            // onRowClick(subItem);
          }}
          className='pointer-cursor'
          style={isRowPointer ? { position: 'relative', cursor: "pointer", paddingLeft: '20px' } : { position: 'relative', paddingLeft: '20px' }}
        >
          {colsData.map((col, colIndex) => {
            if (col.component === 'subHead' && col.subHeader) {  // Loop through subHeader for checkbox in sub-menu
              return (
                col.subHeader.map((subCol, subSubIndex) => (
                  <td key={subSubIndex} {...col.props}>
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={departmentDataSubMenu?.includes(subCol.departmentName) || false}
                      onChange={(e) => {
                        // subItem.checked = e.target.checked;
                        handleCheckbox(e.target.checked, i, subCol?.departmentName, subIndex)

                      }}
                      style={subCol.props.style}
                    />
                  </td>
                ))
              );
            }
            if (colIndex === 0) {
              return <td key={colIndex} {...col.props}></td>; // Empty cell for first column
            }
            if (col.component === 'checkbox') {
              return checkBox(subItem, col);
            }
            if (colIndex === 1) {
              return (
                <td
                  key={colIndex}
                  {...col.props}
                  style={col?.props?.isApplyBackGrundHeader ? { ...col?.props?.style, background: 'none' } : { ...col?.props?.style }}
                >
                  {subItem.name} {/* Sub-menu name in the second column */}
                </td>
              );
            }
            return (
              <td
                {...col.props}
                style={col?.props?.isApplyBackGrundHeader ? { ...col?.props?.style, background: 'none' } : { ...col?.props?.style }}
                onClick={(e) => {
                  if (
                    e.target?.name === 'checkbox' ||
                    col?.preventRowClick === true
                  )
                    return;
                  onRowClick(subItem);
                }}
              >
                {col?.render ? col.render(subItem, col) : subItem[col.dataIndex]}
              </td>
            );
          })}
        </tr>
      });
      return [mainRow, ...(subMenuRows || [])];
    });
    // return <tbody>{rows}</tbody>;
    return dataWithKeys?.length > 0 ? <tbody>{rows}</tbody> : loading ? <>Loading...</> :
      <td colSpan={7}>
        <p style={{ textAlign: 'center', verticalAlign: 'middle', background: '#e5e5e5', width: "100%" }}>No records</p>
      </td>

  }, [dataWithKeys, colsData, rowActions, checkBox, onRowClick, assigneeData]);
  return (
    <div
      className='table-responsive table-height '
      style={{ height: tableHeight || '65vh', marginBottom: '8px' }}
    >
      <table className={`table ${props.className} .data-table-wrapper custom-table`} style={tableWidth ? { width: tableWidth } : {}}>
        {showHeader && (
          <TableHeader
            colsData={colsData}
            dataWithKeys={dataWithKeys}
            rowSelections={rowSelections}
            isAllSelection={isAllSelection}
            onSearchInputChange={onSearchInputChange}
            isLoadDebounceSearchInput={isLoadDebounceSearchInput}
            handleDateChange={handleDateChange}
          />
        )}
        {renderRows()}
      </table>
    </div>
  );
}

export default DataTable;
