/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import * as api from '../../../services/categoryservice';
import { concat } from 'lodash';

export const StateContext = createContext(null);

export default function NewsBlogVideosProvider({ children, ...props }) {
  const initialSetting = {
    name: null,
    meta_title: '',
    meta_keyword: null,
    meta_description: '',
    parent_category_id: '',
    categoryParentOptions: [],
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    searchParams: {},
    categoryList: [],
    editCategory: null,
  };
  const [providerState, setProviderState] = useState(initialSetting);

  const getParentCategoryOptions = useCallback(async () => {
    try {
      const res = await api.getParentCategory();
      if (res?.status === 'Success') {
        let categoryOptionsList = [{ value: '', label: 'Create new' }];
        categoryOptionsList = concat(
          categoryOptionsList,
          res?.data?.map((vl) => {
            return {
              ...vl,
              value: vl?._id,
              label: vl?.name,
            };
          })
        );

        setProviderState((prev) => ({
          ...prev,
          categoryParentOptions: categoryOptionsList,
        }));
      }
    } catch (err) {}
  }, []);

  const getCategoryList = useCallback(async () => {
    try {
      const res = await api.getCategoryList({
        page: providerState?.currentPage,
        limit: providerState?.pageSize,
        ...providerState?.searchParams,
      });
      if (res?.status === 'Success') {
        setProviderState((prev) => ({
          ...prev,
          categoryList: res?.data?.categories,
          totalCount: res?.data?.totalCount,
        }));
      }
    } catch (err) {}
  }, [
    providerState?.currentPage,
    providerState?.pageSize,
    providerState?.searchParams,
  ]);

  useEffect(() => {
    getParentCategoryOptions();
  }, [getParentCategoryOptions]);

  useEffect(() => {
    getCategoryList();
  }, [
    providerState?.currentPage,
    getCategoryList,
    providerState?.searchParams,
  ]);

  const contextValue = {
    providerState,
    setProviderState,
    getCategoryList,
    getParentCategoryOptions,
  };

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
}

export function useNewsBlogsVideoState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error(
      'useAppState must be used within the useNewsBlogsVideoState'
    );
  }
  return context;
}
