import { ActionTypes } from '../actions/actionTypes';

const initialState = {
  managerList: [],
};

export const userManagement = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_MANAGER_LIST:
      return {
        ...state,
        managerList: payload,
      };

    default:
      return state;
  }
};
