import { Button } from 'antd';
import React, { useState } from 'react';
import './invoice.scss';
import { formatDate } from '../../utils/DateUtils';
import { formatPriceWithCommas } from '../../utils/helper';
import FdLogo from '../../assets/images/logo.png';
const PreviewInvoice = ({
  formValues,
  handleEditPreview,
  createInvoice,
  loading,
}) => {
  // console.log('formValues', formValues);
  return (
    <div className='preview__invoice'>
      <div className='invoice'>
        <div className='invoice__header'>
          <div className='invoice__flex'>
            <div className='invoice__left'>
              <div className='logo__section'>
                {/* <h1>
                  Invoice - <span> PR</span>
                </h1> */}
                <h1>Tax Invoice</h1>
                <p className='logo__wrapper'>
                  <img src={FdLogo} alt='image' />
                </p>
              </div>
              <div className='company__address'>
                <p className='mb-1'>Secoya Franchise India Pvt Ltd 10229</p>
                <p>
                  3rd Floor, "Karma Stambh", LBS Rd, Chandan Nagar, Vikhroli
                  West, Mumbai, Maharashtra 400083
                </p>
              </div>
            </div>
            <div className='invoice__right'>
              <div className='invoice__number'>
                <p className='invoice__label'>Invoice #</p>
                <p className='invoice__value'>{formValues?.invoice_number}</p>
              </div>
              <div className='invoice__number'>
                <p className='invoice__label'>Issue Date</p>
                <p className='invoice__value'>
                  {formatDate(new Date(), 'MMM DD, YYYY')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='invoice__bill'>
          <div className='bill__left'>
            <h3>Bill To:</h3>
            <div className='company__address'>
              <p>
                <p className='mb-1'>
                  {formValues?.name}, {formValues?.company_name}
                </p>
                <p>
                  {' '}
                  {formValues?.address}, {formValues?.state?.label},{' '}
                  {formValues?.pincode}{' '}
                </p>
              </p>
            </div>
            <div className='invoice__number'>
              <p className='invoice__label'>
                GST Number: <span>{formValues?.gst}</span>
              </p>
            </div>
          </div>
          <div className='bill__right'>
            <h3>Total Amount:</h3>
            <h1>{formatPriceWithCommas(formValues?.totalAmount)}</h1>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Description/ Plan Summary</th>
              <th>Qty</th>
              <th>Price</th>
              <th>GST</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {formValues?.plan_id?.label === 'Free Package'
                  ? formValues?.title
                  : formValues?.plan_id?.label}{' '}
              </td>
              <td>1 </td>
              <td>{formatPriceWithCommas(formValues?.plan_price)} </td>
              <td>18% </td>
              <td>{formatPriceWithCommas(formValues?.totalAmount)} </td>
            </tr>
          </tbody>
        </table>
        <div className='sub__total'>
          <div className='price__summery'>
            <div className='summery__header'>
              <p>Sub total (excl. GST)</p>
              <p>{formatPriceWithCommas(formValues?.subtotal)} </p>
            </div>
            <div className='summery__body'>
              {formValues?.discountAmount > 0 && (
                <div className='summery__data'>
                  <p>DISCOUNT@:</p>
                  <p>{formValues?.discount || 0}%</p>
                  <p>{formatPriceWithCommas(formValues?.discountAmount)}</p>
                </div>
              )}
              {formValues?.tds > 0 && (
                <div className='summery__data'>
                  <p>TDS@:</p>
                  <p>{formValues?.tds || 0}%</p>
                  <p>{formatPriceWithCommas(formValues?.tdsAmount)}</p>
                </div>
              )}

              {formValues?.cgst > 0 && (
                <div className='summery__data'>
                  <p>CGST@</p>
                  <p>{formValues?.cgst || 0}%</p>
                  <p>{formatPriceWithCommas(formValues?.cgstAmount)}</p>
                </div>
              )}

              {formValues?.sgst > 0 && (
                <div className='summery__data'>
                  <p>SGST@:</p>
                  <p>{formValues?.sgst || 0}%</p>
                  <p>{formatPriceWithCommas(formValues?.sgstAmount)}</p>
                </div>
              )}

              {formValues?.igst > 0 && (
                <div className='summery__data'>
                  <p>IGST@:</p>
                  <p>{formValues?.igst || 0}%</p>
                  <p>{formatPriceWithCommas(formValues?.igstAmount)}</p>
                </div>
              )}
            </div>
            <div className='summery__footer'>
              <p>Total Amount:</p>
              <p>{formatPriceWithCommas(formValues?.totalAmount)}</p>
            </div>
          </div>
        </div>
        <div className='invoice__footer'>
          <div className='footer__left'>
            <div className='terms__wrapper'>
              <div className='terms__left'>
                <h4>Terms and conditions</h4>
                <ul>
                  <li>
                    <b>Services:</b> All services provided by are subject to the
                    terms and conditions outlined herein.
                  </li>
                  <li>
                    <b>Payments:</b> All payments are to be made in accordance
                    with the fees specified for the services rendered.
                  </li>
                  <li>
                    <b>Disputes:</b> Any disputes arising from or in connection
                    with our services shall be resolved exclusively in the
                    jurisdiction of Mumbai.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='footer__right'>
            <h4>Contact</h4>
            <div className='contact__details'>
              <p>Phone: 1800 2100 445</p>
              <p>Email: info@franchisediscovery.in</p>
              <p>Web: www.franchisediscovery.in</p>
              <div style={{ marginTop: '15px' }}>
                <p style={{ marginBottom: '0' }}>
                  CINNO.:U74999MH2019PTC319687
                </p>
                <p>GST NO : 27ABBCS4391P1ZO</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='submit__btn'>
        <Button
          type='primary'
          htmlType='submit'
          onClick={() => createInvoice('generated')}
          loading={loading}
        >
          Generate
        </Button>

        <Button type='default' htmlType='submit' onClick={handleEditPreview}>
          Edit
        </Button>
      </div>
    </div>
  );
};

export default PreviewInvoice;
