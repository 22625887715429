import { ActionTypes } from '../actions/actionTypes';

const initialState = {
  ui: {
    toggleSideMenu: false,
  },
};

export const uiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.TOGGLE_SIDENAV:
      return {
        ...state,
        ui: {
          ...state.ui,
          toggleSideMenu: !state.ui.toggleSideMenu,
        },
      };
    default:
      return state;
  }
};
