import React from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { TiArrowSortedUp } from 'react-icons/ti';
import {
  GetRattingsOptions,
  convertToLowerCaseWithUnderscore,
  getCurrencySymbol,
  convertUnderscoreToTitleCase,
} from '../../../utils/helper';
import Banner from '../../../components/Banner/Banner';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { FiInfo } from 'react-icons/fi';

const CostBifurcation = ({ register, errors, isProfitMarginError }) => {
  const { costBifurcationData } = useSelector((state) => state?.catalog);
  const keysArray = convertToLowerCaseWithUnderscore(costBifurcationData);

  const generateLabelName = (keyname) =>
    `${convertUnderscoreToTitleCase(keyname)}`;
  const generatePlaceholder = (keyname) =>
    `Enter ${convertUnderscoreToTitleCase(keyname)}`;

  return (
    <>
      <Row>
        <Col md={12}>
          <h5 className='d-inline-block me-2'>
            Cost Bifurcation <TiArrowSortedUp style={{ fontSize: '29px' }} />
            <Tooltip
              title='Cost bifurcation helps customers understand your business model and determine potential net profit. Note: If any box does not apply to your cost bifurcation, enter 0.'
              overlayInnerStyle={{ backgroundColor: '#285bd1', color: '#ffffff' }}
            >
              <FiInfo
                size={18}
                style={{ marginLeft: '5px', cursor: 'pointer' }}
              />
            </Tooltip>
          </h5>
          {isProfitMarginError ? (
            <Banner
              message='There is an calculation mistake, net profit cannnot be 0 or less than 0'
              inline={true}
            />
          ) : null}
        </Col>
        <Col md={12}>
          <Row>
            {keysArray &&
              keysArray.length > 0 &&
              keysArray.map((keyname, index) => (
                <Col md={4} key={index}>
                  <Form.Group
                    className='mb-3'
                    controlId={`costbifurcationinfo.${keyname}`}
                  >
                    <Form.Label>{generateLabelName(keyname)}</Form.Label>
                    <InputGroup
                      hasValidation
                      className='position-relative icon-box'
                    >
                      <Form.Control
                        type='number'
                        placeholder={generatePlaceholder(keyname)}
                        {...register(`costbifurcationinfo.${keyname}`)}
                        isInvalid={!!errors?.costbifurcationinfo?.[keyname]}
                      ></Form.Control>
                      <InputGroup.Text>%</InputGroup.Text>
                      <Form.Control.Feedback type='invalid'>
                        {errors?.costbifurcationinfo?.[keyname]?.message}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              ))}
            <Col md={4}>
              <Form.Group
                className='mb-3'
                controlId='costbifurcationinfo.profitmargin'
              >
                <Form.Label>Profit Margin</Form.Label>
                <InputGroup
                  hasValidation
                  className='position-relative icon-box'
                >
                  <Form.Control
                    type='number'
                    placeholder={'Enter Profit Margin'}
                    {...register('costbifurcationinfo.profitmargin')}
                    isInvalid={!!errors?.costbifurcationinfo?.profitmargin}
                    disabled
                  ></Form.Control>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control.Feedback type='invalid'>
                    {errors?.costbifurcationinfo?.profitmargin?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CostBifurcation;
