import React from 'react';
import { Alert } from 'antd';

const Banner = ({
  type = 'error',
  message = '',
  closable = false,
  onClose,
  inline = false,
  ...rest
}) => {
  return (
    <div className={inline ? 'd-inline-block' : ''}>
      <Alert
        type={type}
        message={message}
        closable={closable}
        onClose={onClose}
        {...rest}
      />
    </div>
  );
};

export default Banner;
