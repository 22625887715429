import React from 'react';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { FiX } from 'react-icons/fi';
import './toast.scss';
const ToastComponent = () => {
  return (
    <Toaster
      position='bottom-center'
      reverseOrder={true}
      gutter={8}
      containerClassName='toast__container'
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: 'toast__bar',
        duration: 5000,
        style: {
          background: '#000',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          duration: 5000,
          theme: {
            primary: 'green',
            secondary: 'black',
          },
        },
      }}
    >
      {(t) => (
        <ToastBar
          toast={t}
          position='bottom-center'
          style={{
            ...t.style,
          }}
        >
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button
                  className='toast__close-btn'
                  onClick={() => toast.dismiss(t.id)}
                >
                  <FiX />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ToastComponent;
