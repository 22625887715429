import nProgress from 'nprogress';
import React, { useEffect } from 'react';
import Spinner from '../components/Loader/Spinner';

const LazyLoad = () => {
  useEffect(() => {
    nProgress.start();
    return () => nProgress.done();
  }, []);

  return <Spinner />;
};

export default LazyLoad;
