import { ActionTypes } from '../actions/actionTypes';
const initialState = {
  catalogSelectedBrandId: null,
  catalogSelectedBrandDetails: null,
};

export const catalogApprovalReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_BRANDID:
      return {
        ...state,
        catalogSelectedBrandId: payload,
      };
    case ActionTypes.SET_SELECTED_BRAND_DETIALS:
      return {
        ...state,
        catalogSelectedBrandDetails: payload,
      };
    default:
      return state;
  }
};
