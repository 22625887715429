import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { logout } from '../../redux/actions/authActions';
import { FiChevronDown } from 'react-icons/fi';
import HeaderLogo from '../../assets/images/Franchise_logo_header.png';

import './header.style.scss';
import { Dropdown, Menu } from 'antd';

const Header = (props) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const items = [
  //   {
  //     key: '1',
  //     label: (
  //       <span
  //         onClick={() => {
  //           handleLogout();
  //         }}
  //         style={{
  //           cursor: 'pointer',
  //         }}
  //       >
  //         Logout
  //       </span>
  //     ),
  //   },
  // ];
  const handleLogout = () => {
    dispatch(logout());
    localStorage.setItem('isUrlSet', false);
  };
  const profile = auth?.profile;
  const menu = (
    <Menu>
      <Menu.Item
        key='1'
        onClick={() => {
          handleLogout();
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className='row header'>
        <div className='col-md-3'>
          <img src={HeaderLogo} alt='Franchise World Logo' />
          <span className='logo-text'>
            Franchise
            <br /> Discovery
          </span>
        </div>
        <div className='col-md-7'></div>
        <div className='col-md-2 position-relative'>
          {auth.user && (
            <Dropdown
              // menu={{
              //   items,
              // }}
              placement='bottom'
              overlay={menu}
            >
              <button
                className={`text-start bg-white border-gray border py-0 w-100 h-100`}
                //onClick={dropdownToggle}
                //onClick={handleLogout}
              >
                <FiChevronDown
                  size={18}
                  className='fd-font-16 float-end mt-1'
                />
                <p className='text-primary fd-font-14 my-0'>{`${
                  profile?.fullname || ''
                }`}</p>
                {/* <small className='text-secondary fd-font-10'>
                  Fleet Manager
                </small> */}
              </button>
            </Dropdown>
          )}
        </div>
      </div>
      {/* <nav className="navbar">
                <NavLink to='demo'>Demo</NavLink>
                {!auth.user && (
                    <NavLink to='/login'> Login </NavLink>
                )}
                {auth.user && (
                    <button type='button' onClick={handleLogout}> Logout </button>
                )}
            </nav> */}
    </>
  );
};

export default Header;
