import { getPlans } from '../services/plansServices';
import React, { useState, useEffect, useCallback } from 'react';

const usePlansData = ({ investment = 500000 }) => {
  const [plans, setPlans] = useState([]);
  const getAllPlans = useCallback(async () => {
    try {
      const res = await getPlans({
        investment: investment * 100000,
        duration: '12m',
      });
      if (res.status === 'Success') {
        setPlans(res.data);
      }
    } catch (error) {
    } finally {
    }
  }, [investment]);

  useEffect(() => {
    getAllPlans();
  }, [investment]);

  return {
    plans,
  };
};

export default usePlansData;
