export const getToken = () => {
  return 'Bearer' + ' ' + localStorage.getItem('token');
};

export const getAttributeValue = (obj, attrName) => {
  let value = null;
  if (obj && obj.hasOwnProperty(attrName)) {
    return obj[attrName];
  } else {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object') {
        value = getAttributeValue(obj[prop], attrName);
        if (value !== null) {
          return value;
        }
      }
    }
  }
  return value;
};
