import { ActionTypes } from '../actions/actionTypes';

const initialState = {
  user: null,
  profile: {},
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: payload,
      };
    case ActionTypes.REMOVE_USER:
      return {
        user: null,
      };
    case ActionTypes.GET_PROFILE:
      return {
        ...state,
        profile: payload?.data,
      };
    default:
      return state;
  }
};
