import React, { useEffect, useState } from 'react';
import './Search.Style.scss';
import { IoIosSearch } from 'react-icons/io';

import useSearchDebounce from '../../hooks/useDebounce';
function SearchInput({ onChange }) {
  const [value, setValue] = useState('');
  const searchTerm = useSearchDebounce(value, 500);

  useEffect(() => {
    onChange(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  return (
    <div className='search-container'>
      {/* <i className='search-icon  bi bi-search'></i> */}
      <IoIosSearch className='search-icon' size={20} />

      <input
        type='text'
        className='search-input'
        placeholder='Search...'
        // value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}

export default React.memo(SearchInput);
