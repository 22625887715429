import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import nprogress from 'nprogress';
import DataTable from '../../components/DataTable';
import { formatDate } from '../../utils/DateUtils';
import { AiFillEdit, AiFillDelete, AiOutlineSearch } from 'react-icons/ai';
import { useCallback } from 'react';
import Pagination from '../../components/Pagination';
import { toast } from 'react-hot-toast';
import * as API from '../../services/invoiceService';
import { flattenObject, printDocumentFromFile } from '../../utils/helper';
import CustomDatePicker from '../../components/CustomDatePicker';
import { Button, Input } from 'antd';
import '.././Invoice/invoice.scss';
import { DownloadOutlined } from "@ant-design/icons";

const columnsDataMock = [
    {
        title: 'Invoice ID',
        dataIndex: 'invoice_number',
        props: {
            className: '',
            style: {
                width: '150px',
            },
        },
    },

    {
        title: 'Mobile',
        dataIndex: 'mobile',
        render: (record) => {
            return record?.mobile || '--';
        },
        props: {
            className: '',
        },
    },
    {
        title: 'Email Id',
        dataIndex: 'email',
        render: (record) => {
            return record?.email || '--';
        },
        props: {
            className: '',
        },
    },

    {
        title: 'Plan',
        dataIndex: 'plan_name',
        props: {
            className: '',
        },
    },
    {
        title: 'Invoice Generation Date',
        dataIndex: 'createdAt',
        render: (record, col) => {
            return record.createdAt ? formatDate(record.createdAt, 'MM/DD/YYYY') : '--';
        },
        props: {
            className: '',
        },
    },
    {
        title: 'Download',
        dataIndex: 'actions',
        props: {},
    },
];

const InvestorsInvoices = (props) => {
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [columns, setColumns] = useState(columnsDataMock);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const actions = useCallback((record, col) => {
        return (
            <div style={{ marginLeft: '30px' }}>
                <DownloadOutlined title='Download' onClick={() => downloadInvoice(record, col)} disabled={!record?.invoice_number} />
            </div>
        );
    }, []);

    const onSelectRow = useCallback(
        (row, value) => {
            if (!value) {
                setSelectedRows([
                    ...selectedRows.filter((item) => item.key_id !== row.key_id),
                ]);
            }

            if (value) {
                setSelectedRows([...selectedRows, row]);
            }
        },
        [selectedRows]
    );

    const onSelectAllRows = useCallback(
        (rows, value) => {
            value ? setSelectedRows([...selectedRows, ...rows]) : setSelectedRows([]);
        },
        [selectedRows]
    );

    const onRowClick = (row) => { };

    const onSearchInputChange = (value, dataIndex) => {
        setSearchParams((params) => ({ ...params, [dataIndex]: value }));
    };

    const formatInvoiceData = (invoices) => {
        let values = invoices.map((el) => {
            return flattenObject(el);
        });
        return values;
    };

    const getAllInvoices = useCallback(async () => {
        try {
            setLoading(true);
            nprogress.start();
            const res = await API.getAllInvoices({
                page: currentPage,
                limit: pageSize,
                user_type: "investor",
                ...searchParams,
            });

            if (res.status === 'Success') {
                setTotalCount(res.data.totalDoc);
                if (res.data.invoices.length) {
                    setData(formatInvoiceData(res.data.invoices));
                } else {
                    setData([]);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            nprogress.done();
        }
    }, [currentPage, pageSize, searchParams]);

    useEffect(() => {
        getAllInvoices();
    }, [currentPage, getAllInvoices]);

    const onChangeDateRange = (dateRange) => {
        setSearchParams((params) => ({
            ...params,
            start_date: dateRange.fromDate || undefined,
            end_date: dateRange.toDate || undefined,
        }));
    };
    const onDraftTabChnage = (tab) => {
        setSearchParams({
            ...searchParams,
            invoice_type: tab,
            proforma: undefined,
        });
    };
    const onProformaTabChange = (tab) => {
        setSearchParams({
            ...searchParams,
            proforma: tab,
            invoice_type: undefined,
        });
    };
    const onAllTabChange = () => {
        setSearchParams({
            ...searchParams,
            invoice_type: undefined,
            proforma: undefined,
        });
    };
    const onGlobalSeacrh = (value, dataIndex) => {
        setSearchParams((params) => ({
            ...params,
            [dataIndex]: value,
        }));
    };

    const downloadInvoice = async (record, col) => {
        console.log('record', record);
        nprogress.start();
        let params = {
            invoice_id: record?._id,
        };
        try {
            const response = await API.downloadInvoice(params);
            console.log('response', response);
            if (response?.status == 'Failed') {
                toast.error(response?.message);
                return;
            }
            //console.log('API Response:', response);

            const downloadLink = document.createElement('a');
            const blob = new Blob(['\ufeff', response]);
            const url = URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = `invoice_${record?.invoice_number}.pdf`; // Name the file here
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error during download:', error);
        } finally {
            nprogress.done();
        }
    };

    // const exportInvoices = useCallback(async () => {
    //     try {
    //         setLoading(true);
    //         nprogress.start();
    //         const res = await API.exportAllInvoices({
    //             user_type: "investor",
    //             ...searchParams,
    //         });

    //         if (res.status === 'Success') {
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     } finally {
    //         setLoading(false);
    //         nprogress.done();
    //     }
    // }, [searchParams]);

    return (
        <div className='container-fluid'>
            <div className='header-section'>
                <div className='header-left'>
                    <span className='header_title'>Investor Invoices ({totalCount})</span>
                    <CustomDatePicker onChangeValues={onChangeDateRange} width="50%" title="Filter By Billing Date" withToolTip />
                </div>
                <div className='header-right'>
                    <Input
                        placeholder='Search...'
                        prefix={<AiOutlineSearch style={{ color: 'rgba(0,0,0,.25)' }} />}
                        onChange={(e) => onGlobalSeacrh(e.target.value, 'search')}
                    />
                    {/* <Button onClick={exportInvoices}>Export</Button> */}
                </div>
            </div>
            <DataTable
                rowActions={actions}
                className='table-striped table-sm'
                columns={columns}
                data={data}
                currentPage={currentPage}
                pageSize={pageSize}
                onRowClick={onRowClick}
                onSearchInputChange={onSearchInputChange}
                loading={loading}
                rowSelections={{
                    selectedRows: selectedRows,
                    onSelectRow: onSelectRow,
                    onSelectAllRows: onSelectAllRows,
                    uniqueId: 'email',
                }}
                showSelection={true}
            />
            <div className='pagination-container'>
                <div className='page-size'>
                    Showing{' '}
                    <select
                        value={pageSize}
                        name='pageSize'
                        onChange={(e) => {
                            setPageSize(parseInt(e.target.value, 10));
                            setCurrentPage(1);
                        }}
                    >
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='50'>50</option>
                    </select>{' '}
                    of {totalCount} results
                </div>
                <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>
        </div>
    );
};

export default InvestorsInvoices;
