import axios from 'axios';
import { AUTH_TOKEN_KEY } from '../constants/constant';
import { getParsedItem } from '../utils/Storage';
import { toast } from 'react-hot-toast';

const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;

const defaultHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Content-Type': 'application/json',
};

export const defaultAxios = axios.create({
  baseURL: BASE_URL,
});

export function apiClient({
  url,
  data = {},
  method = 'GET',
  headers = {},
  noHeaders,
  ...rest
}) {
  const token = getParsedItem(AUTH_TOKEN_KEY);
  return new Promise((resolve, reject) => {
    defaultAxios({
      method,
      url,
      headers: {
        ...(noHeaders ? {} : defaultHeaders),
        ...headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      data,
      ...rest,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        let errorMessage = '';
        const additionalData = err?.response?.data?.error?.additional_data;
        if (additionalData && typeof additionalData === 'object') {
          // Define the keys to ignore
          const ignoreKeys = ['status_code', 'err_code', 'err_message'];
      
          // Find the first key that is not in the ignore list
          const otherKey = Object.keys(additionalData).find(key => !ignoreKeys.includes(key));
      
          if (otherKey) {
              // If another key exists, use its value
              errorMessage = additionalData[otherKey];
          } else {
              // Otherwise, use 'err_message' value
              errorMessage = additionalData.err_message || errorMessage;
          }
      }
      else if (additionalData && typeof additionalData === 'string') {
            errorMessage = additionalData.err_message || additionalData|| errorMessage;
    }
        errorMessage = errorMessage || err?.response?.data?.error?.additional_data?.err_message  || 'Failed';
      
        toast.error(errorMessage);
        if (err?.response?.status === 401) {
          //localStorage.removeItem(AUTH_TOKEN_KEY);
          //window.location.href = "/login";
        }
        reject(err?.response || err);
      });
  });
}
