import { ActionTypes } from './actionTypes';
import {
  AUTH_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
} from '../../constants/constant';
import { removeItem } from '../../utils/Storage';
import { resetCatalog } from './catalogActions';
import authServices from '../../services/authServices';

export const setUser = (user) => {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
};

export const removeUser = () => {
  return {
    type: ActionTypes.REMOVE_USER,
  };
};

export const logout = () => async (dispatch) => {
  dispatch(removeUser());
  removeItem(AUTH_TOKEN_KEY);
  removeItem(AUTH_REFRESH_TOKEN_KEY);
  dispatch(resetCatalog());
  // setTimeout(() => {
  //   window.location.href = '/login';
  // }, 100);
};

export const getProfileData = () => async (dispatch) => {
  try {
    const response = await authServices.getProfile();
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.GET_PROFILE,
        payload: response,
      });
    }
  } catch (error) {
  } finally {
  }
};
