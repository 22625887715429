import { apiClient } from '../config/brandClient';
import { apiClient as masterClient } from '../config/masterManagementClient';
import { API_ENDPOINTS } from '../constants/endpoints';
const { COUNTRY } = API_ENDPOINTS;

export const getCountriesData = async (params) => {
  try {
    const { url, method } = COUNTRY.getCountries();
    const res = await masterClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};

export const getStatesData = async (params) => {
  try {
    const { url, method } = COUNTRY.getStates();
    const res = await masterClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};

export const getCitiesData = async (params) => {
  try {
    const { url, method } = COUNTRY.getCities();
    const res = await masterClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};

export const getCatalogKeywords = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const [url, method] = ['/api/v1/brandservice/catalogues/keyword', 'GET'];
      const res = await apiClient({ url: url, method, params: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogKeywordsWithCategory = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const [url, method] = ['/api/v1/keywords/suggestion', 'GET'];
      const res = await apiClient({ url: url, method, params: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllZones = async (params) => {
  try {
    const { url, method } = COUNTRY.getZones();
    const res = await masterClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};

export default {
  getCountriesData,
  getStatesData,
  getCitiesData,
  getCatalogKeywords,
  getCatalogKeywordsWithCategory,
  getAllZones,
};
