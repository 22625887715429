import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Select, Input, Button, Space, Row, Col } from 'antd';
import CustomMultiSelect from '../../components/Form/CustomSelect/CustomMultiSelect';
import useLocationData from '../../hooks/useLocationData';
import {
  getLabelValueOptions,
  findCountryIsoCode,
  findStateIsoCode,
  numberWithCommas,
  findCountryPhoneCode,
} from '../../utils/helper';
import usePlansData from '../../hooks/usePlansData';

const INPUT_SIZE = 'middle';
const InvoiceForm = ({
  formValues,
  handleInputChange,
  handleSelectChange,
  totalAmount,
  handlePreview,
  createInvoice,
  setCountryCode,
  loading,
}) => {
  const { countries, states, cities, handleUpdateCountry, handleUpdateState } =
    useLocationData();
  const { plans } = usePlansData({ investment: formValues?.plan_price });
  const countryOptions = useMemo(() => {
    return getLabelValueOptions(countries);
  }, [countries]);
  const stateOptions = useMemo(() => {
    return getLabelValueOptions(states);
  }, [states]);
  const cityOptions = useMemo(() => {
    return getLabelValueOptions(cities);
  }, [cities]);

  const handleCountryChange = (value) => {
    let countryIsoCode = findCountryIsoCode(countries, value?.label);
    handleUpdateCountry(countryIsoCode);
    setCountryCode(findCountryPhoneCode(countries, value?.label));
  };
  const handleStateChange = (value) => {
    let stateIsoCode = findStateIsoCode(states, value?.label);
    handleUpdateState(stateIsoCode);
  };
  const getPlanOptions = useMemo(() => {
    return plans.map((plan) => ({
      value: plan._id,
      label: plan.name,
    }));
  }, [plans]);
  return (
    <div className='invoice__form'>
      <div className='invoice__form-section'>
        <h3>Bill to</h3>
        <Row gutter={16}>
          <Col span={12}>
            <div className='form__item'>
              <label>Customer Name</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Customer Name'
                value={formValues?.name}
                onChange={handleInputChange}
                name='name'
              />
            </div>
          </Col>

          <Col span={12}>
            <div className='form__item'>
              <label>Company Name</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Company Name'
                value={formValues?.company_name}
                onChange={handleInputChange}
                name='company_name'
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className='form__item'>
              <label>GST Number</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter GST number'
                value={formValues?.gst}
                onChange={handleInputChange}
                name='gst'
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <div className='form__item'>
              <label>Email</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Email'
                value={formValues?.email}
                onChange={handleInputChange}
                name='email'
              />
            </div>
          </Col>

          <Col span={12}>
            <div className='form__item'>
              <label>Phone</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Phone'
                value={formValues?.mobile}
                onChange={handleInputChange}
                name='mobile'
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className='form__item'>
              <label>Billing Address</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Billing Address'
                value={formValues?.address}
                onChange={handleInputChange}
                name='address'
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <div className='form__item'>
              <label>Pin code</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Pin code'
                value={formValues?.pincode}
                onChange={handleInputChange}
                name='pincode'
              />
            </div>
          </Col>

          <Col span={12}>
            <div className='form__item'>
              <label>Country</label>
              <CustomMultiSelect
                options={countryOptions}
                placeholder='Select Country'
                labelInValue={true}
                size={INPUT_SIZE}
                mode='unset'
                //onChange={handleCountryChange}
                value={formValues?.country}
                onChange={(value) => {
                  handleSelectChange(value, 'country');
                  handleCountryChange(value);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <div className='form__item'>
              <label>State</label>
              <CustomMultiSelect
                options={stateOptions}
                placeholder='Select State'
                labelInValue={true}
                size={INPUT_SIZE}
                mode='unset'
                value={formValues?.state}
                onChange={(value) => {
                  handleSelectChange(value, 'state');
                  handleStateChange(value);
                }}
              />
            </div>
          </Col>

          <Col span={12}>
            <div className='form__item'>
              <label>City</label>
              <CustomMultiSelect
                options={cityOptions}
                placeholder='Select City'
                labelInValue={true}
                size={INPUT_SIZE}
                mode='unset'
                value={formValues?.city}
                onChange={(value) => handleSelectChange(value, 'city')}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className='invoice__form-section'>
        <h3>Plan summary</h3>
        {!formValues?.is_free && (
          <Row gutter={16}>
            <Col span={12}>
              <div className='form__item'>
                <label>Add Plans</label>
                <CustomMultiSelect
                  options={getPlanOptions}
                  placeholder='Select Plan'
                  labelInValue={true}
                  size={INPUT_SIZE}
                  mode='unset'
                  value={formValues?.plan_id}
                  onChange={(value) => {
                    handleSelectChange(value, 'plan_id');
                  }}
                  disabled
                />
              </div>
            </Col>

            <Col span={12}>
              <div className='form__item'>
                <label>Rate</label>
                <Input
                  size={INPUT_SIZE}
                  placeholder='Enter Rate'
                  value={formValues?.plan_price}
                  onChange={handleInputChange}
                  name='plan_price'
                  type='number'
                  disabled
                />
              </div>
            </Col>
          </Row>
        )}

        {!formValues?.is_manual && (
          <Row gutter={16}>
            <Col span={12}>
              <div className='form__item'>
                <label>Personalize Rate(PR)</label>
                <Input
                  size={INPUT_SIZE}
                  placeholder='Enter Personalize Rate(PR)'
                  value={formValues?.plan_price}
                  onChange={handleInputChange}
                  name='plan_price'
                  type='number'
                />
              </div>
            </Col>

            <Col span={12}>
              <div className='form__item'>
                <label>Description</label>
                <Input
                  size={INPUT_SIZE}
                  placeholder='Enter Description'
                  value={formValues?.title}
                  onChange={handleInputChange}
                  name='title'
                  type='text'
                />
              </div>
            </Col>
          </Row>
        )}
      </div>

      <div className='invoice__form-section'>
        <h3>Payment</h3>
        <Row gutter={16}>
          <Col span={8}>
            <div className='form__item'>
              <label>Subtotal</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Subtotal'
                value={formValues?.subtotal}
                onChange={handleInputChange}
                name='subtotal'
                type='number'
              />
            </div>
          </Col>
          <Col span={8}>
            <div className='form__item'>
              <label>Discount</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Discount'
                value={formValues?.discount}
                onChange={handleInputChange}
                name='discount'
                type='number'
                suffix='%'
              />
            </div>
          </Col>

          <Col span={8}>
            <div className='form__item'>
              <label>TDS@</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter TDS@'
                value={formValues?.tds}
                onChange={handleInputChange}
                name='tds'
                type='number'
                suffix='%'
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <div className='form__item'>
              <label>CGST@</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter CGST@'
                value={formValues?.cgst}
                onChange={handleInputChange}
                name='cgst'
                type='number'
                disabled
                suffix='%'
              />
            </div>
          </Col>

          <Col span={8}>
            <div className='form__item'>
              <label>SGST@</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter SGST@'
                value={formValues?.sgst}
                onChange={handleInputChange}
                name='sgst'
                type='number'
                disabled
                suffix='%'
              />
            </div>
          </Col>
          <Col span={8}>
            <div className='form__item'>
              <label>IGST@</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter IGST@'
                value={formValues?.igst}
                onChange={handleInputChange}
                name='igst'
                type='number'
                disabled
                suffix='%'
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8} className='total__amount'>
            <label>Total</label>
            <span>Rs. {numberWithCommas(totalAmount || 0)}</span>
          </Col>

          <Col span={8}>
            <div className='form__item'>
              <label>Amount Paid</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Amount Paid'
                value={totalAmount}
                onChange={handleInputChange}
                name='price_paid'
                type='number'
                disabled
              />
            </div>
          </Col>
          <Col span={8}>
            <div className='form__item'>
              <label>Balance Amount</label>
              <Input
                size={INPUT_SIZE}
                placeholder='Enter Balance Amount'
                value={formValues?.balance_amount}
                onChange={handleInputChange}
                name='balance_amount'
                type='number'
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className='submit__btn'>
        <Button type='primary' htmlType='button' onClick={handlePreview}>
          Preview
        </Button>

        <Button
          type='default'
          htmlType='submit'
          onClick={() => createInvoice('draft')}
          loading={loading}
        >
          Save as draft
        </Button>
      </div>
    </div>
  );
};

export default InvoiceForm;
