import axios from 'axios';
import { refreshToken } from '../services/authServices';
import { AUTH_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY } from '../constants/constant';
import { getParsedItem, removeItem, setParsedItem } from '../utils/Storage';
import { logout } from '../redux/actions/authActions';
import store from '../redux/store';

let isRefreshing = false;
let refreshTokenPromise = null;

const restrictUrlsForRefreshToken = [
  '/api/v1/login/refresh',
  '/api/v1/login/fd-user',
];

export const createAxiosInstance = (baseURL) => {
  const { dispatch } = store;

  const instance = axios.create({
    baseURL: baseURL,
  });

  instance.interceptors.request.use(
    async (config) => {
      const token = getParsedItem(AUTH_TOKEN_KEY);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const refresh_token = getParsedItem(AUTH_REFRESH_TOKEN_KEY);

      if (error.response.status === 401) {
        if (!refresh_token) {
          // If there's no refresh token, logout the user immediately
          return logoutUser(dispatch);
        }

        if (
          !originalRequest._retry &&
          !restrictUrlsForRefreshToken.includes(originalRequest.url)
        ) {
          originalRequest._retry = true;

          if (!isRefreshing) {
            isRefreshing = true;
            refreshTokenPromise = refreshTokenHandler(dispatch);
          }

          return enqueueRequest(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const refreshTokenHandler = async (dispatch) => {
  try {
    const token = getParsedItem(AUTH_REFRESH_TOKEN_KEY);
    const response = await refreshToken({ token });
    const accessToken = response?.data?.access_token;
    setParsedItem(AUTH_TOKEN_KEY, accessToken);
    return accessToken;
  } catch (error) {
    console.error('Token refresh failed:', error);
    return logoutUser(dispatch);
  } finally {
    isRefreshing = false;
  }
};

const enqueueRequest = (request) => {
  return new Promise((resolve, reject) => {
    refreshTokenPromise
      .then((accessToken) => {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
        resolve(axios(request));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const logoutUser = (dispatch) => {
  dispatch(logout());
  window.location.href = '/login';
  removeItem(AUTH_TOKEN_KEY);
  removeItem(AUTH_REFRESH_TOKEN_KEY);
  return Promise.reject('Authentication failed');
};
