import React from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import './pagination.style.scss';
import { usePagination, DOTS } from './usePagination';
function Pagination({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage === lastPage) return;
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage === 1) return;
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <div className='pagination-wrapper'>
      <span
        onClick={onPrevious}
        className={`arrow ${currentPage === 1 ? 'disable' : ''}`}
      >
        <BsChevronLeft />
      </span>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <span className='dots'>&#8230;</span>;
        }

        return (
          <span
            className={` ${currentPage === pageNumber ? 'active-page' : ''}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </span>
        );
      })}
      <span
        className={`arrow ${currentPage === lastPage ? 'disable' : ''}`}
        onClick={onNext}
      >
        <BsChevronRight />
      </span>
    </div>
  );
}

export default Pagination;
