import { ActionTypes } from './actionTypes';
import catalogServices from '../../services/catalogServices';
import nProgress from 'nprogress';
import { notification } from 'antd';
import miscServices from '../../services/miscServices';
import { toast } from 'react-hot-toast';

export const resetCatalog = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_CATALOG,
  });
};

export const setCatalog = (payload) => ({
  type: ActionTypes.SET_CATALOG,
  payload,
});

export const updateCatalogCount = (count) => {
  return {
    type: ActionTypes.SET_CATALOG_COUNT,
    payload: count,
  };
};

export const updateCatelog = (payload, catelogSection) => async (dispatch) => {
  let sendPayload = {
    data: payload,
    catelogSection: catelogSection,
  };
  dispatch({
    type: ActionTypes.UPDATE_CATELOG,
    payload: sendPayload,
  });
};

export const setCatalogFormEditable = (isEdit) => {
  return {
    type: ActionTypes.SET_CATALOG_FORM_EDITABLE,
    payload: {
      isEdit,
    },
  };
};

export const updateCatalogFormFailure = (error) => {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return {
    type: ActionTypes.MANAGE_CATALOG_FORM_FAILURE,
    payload: message,
  };
};

export const postCatalogueBrandDetailsAction =
  (payload) => async (dispatch) => {
    dispatch({
      type: ActionTypes.MANAGE_CATALOG_FORM_LOADING,
    });
    nProgress.start();
    try {
      const response = await catalogServices.postCatalogueBrandDetails(payload);
      nProgress.done();
      if (response.status == 'Success') {
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_BRAND_FORM_SUCCESS,
          payload: response.data,
        });
        dispatch(updateCatalogCount(1));
      } else {
        notification.error({
          message: response?.message,
          description: response?.error,
        });
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      nProgress.done();
      dispatch(updateCatalogFormFailure(error));
    }
  };

export const postCatalogueFranchiseDetailsAction =
  (payload) => async (dispatch) => {
    dispatch({
      type: ActionTypes.MANAGE_CATALOG_FORM_LOADING,
    });
    nProgress.start();
    try {
      const response = await catalogServices.postCatalogueFranchiseDetails(
        payload
      );
      nProgress.done();
      if (response.status == 'Success') {
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_SUCCESS,
          payload: response.data,
        });
        dispatch(updateCatalogCount(2));
      } else {
        notification.error({
          message: response?.message,
          description: response?.error,
        });
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Bad Request',
      });
      console.log('error', error);
      nProgress.done();
      dispatch(updateCatalogFormFailure(error));
    }
  };

export const postCataloguePagesDetailsAction =
  (payload) => async (dispatch) => {
    dispatch({
      type: ActionTypes.MANAGE_CATALOG_FORM_LOADING,
    });
    nProgress.start();
    try {
      const response = await catalogServices.postCataloguePagesDetails(payload);
      nProgress.done();
      if (response.status == 'Success') {
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_SUCCESS,
          payload: response.data,
        });
        dispatch(updateCatalogCount(3));
      } else {
        notification.error({
          message: response?.message,
          description: response?.error,
        });
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      nProgress.done();
      dispatch(updateCatalogFormFailure(error));
    }
  };

export const postCatalogueAdditionalDetailsAction =
  (payload) => async (dispatch) => {
    dispatch({
      type: ActionTypes.MANAGE_CATALOG_FORM_LOADING,
    });
    nProgress.start();
    try {
      const response = await catalogServices.postCatalogueAdditionalDetails(
        payload
      );
      nProgress.done();
      if (response.status == 'Success') {
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_SUCCESS,
          payload: response.data,
        });
        dispatch(updateCatalogCount(4));
      } else {
        notification.error({
          message: response?.message,
          description: response?.error,
        });
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      nProgress.done();
      dispatch(updateCatalogFormFailure(error));
    }
  };

export const postCataloguePhotosDocsDetailsAction =
  (payload) => async (dispatch) => {
    dispatch({
      type: ActionTypes.MANAGE_CATALOG_FORM_LOADING,
    });
    nProgress.start();
    try {
      const response = await catalogServices.postCataloguePhotosDocslDetails(
        payload
      );
      nProgress.done();
      if (response.status == 'Success') {
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_SUCCESS,
          payload: response.data,
        });
        dispatch(updateCatalogCount(5));
      } else {
        notification.error({
          message: response?.message,
          description: response?.error,
        });
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      nProgress.done();
      dispatch(updateCatalogFormFailure(error));
    }
  };

export const postCatalogueAlternativeContactDetailsAction =
  (payload) => async (dispatch) => {
    dispatch({
      type: ActionTypes.MANAGE_CATALOG_FORM_LOADING,
    });
    nProgress.start();
    try {
      const response =
        await catalogServices.postCatalogueAlternativeContactDetails(payload);
      nProgress.done();
      if (response.status == 'Success') {
        toast.success('Brand Catalogue updated successfully!');
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_SUCCESS,
          payload: response.data,
        });
      } else {
        notification.error({
          message: response?.message,
          description: response?.error,
        });
        dispatch({
          type: ActionTypes.MANAGE_CATALOG_FORM_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      notification.error('Error');
      nProgress.done();
      dispatch(updateCatalogFormFailure(error));
    }
  };

export const getCatalogDetailsAction = (payload) => async (dispatch) => {
  dispatch({
    type: ActionTypes.FETCH_CATALOG_DETAILS_LOADING,
  });
  nProgress.start();
  try {
    const response = await catalogServices.getCatalogDetails(payload);
    nProgress.done();
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.FETCH_CATALOG_DETAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.FETCH_CATALOG_DETAILS_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    nProgress.done();
    dispatch({
      type: ActionTypes.FETCH_CATALOG_DETAILS_FAILURE,
      payload: error?.response?.message,
    });
  }
};
export const getDropdownData = () => async (dispatch) => {
  nProgress.start();
  try {
    const response = await catalogServices.getDropdownData();
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.GET_DROPDOWN_DATA,
        payload: response,
      });
    }
  } catch (error) {
  } finally {
    nProgress.done();
  }
};
export const getIndustryData = () => async (dispatch) => {
  nProgress.start();
  try {
    const response = await catalogServices.getIndustryData();
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.GET_INDUSTRY,
        payload: response,
      });
    }
  } catch (error) {
  } finally {
    nProgress.done();
  }
};

export const getBussinessCategoryData = () => async (dispatch) => {
  nProgress.start();
  try {
    const response = await catalogServices.getBussinessCategoryData();
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.GET_BUSSINESS_CATEGORY_DATA,
        payload: response,
      });
    }
  } catch (error) {
  } finally {
    nProgress.done();
  }
};

export const getBussinessTypeData = (sup_cat_id) => async (dispatch) => {
  try {
    const response = await catalogServices.getBussinessTypeData(sup_cat_id);
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.GET_BUSSINESS_TYPE_DATA,
        payload: response,
      });
    }
  } catch (error) {
  } finally {
  }
};
export const getCostBifurcationsData = (sup_cat_id) => async (dispatch) => {
  try {
    const response = await catalogServices.getCostBifurcationsData(sup_cat_id);

    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.GET_COST_BIFURCATION_DATA,
        payload: response?.data,
      });
    }
  } catch (error) {
  } finally {
  }
};
export const getEmployeeDetailsData = (sup_cat_id) => async (dispatch) => {
  try {
    const response = await catalogServices.getEmployeeDetailsData(sup_cat_id);
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.GET_EMPLOYEE_DETAILS_DATA,
        payload: response?.data,
      });
    }
  } catch (error) {
  } finally {
  }
};

export const getLicenseTypeData = (sup_cat_id) => async (dispatch) => {
  try {
    const response = await catalogServices.getLicenseTypeData(sup_cat_id);
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.GET_LICENSE_TYPE_DATA,
        payload: response?.data,
      });
    }
  } catch (error) {
  } finally {
  }
};
export const setBrandUser = (user) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_BRAND_USER,
    payload: user,
  });
};

export const setNewCatalogData = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_NEW_CATALOG_DATA,
    payload: data,
  });
};

export const fetchCountryData = () => async (dispatch) => {
  try {
    const response = await miscServices.getCountriesData();
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.FETCH_COUNTRIES,
        payload: response?.data,
      });
    }
  } catch (error) {
  } finally {
  }
};

export const fetchStatesData = (params) => async (dispatch) => {
  try {
    const response = await miscServices.getStatesData(params);
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.FETCH_STATES,
        payload: response?.data,
      });
    }
  } catch (error) {
  } finally {
  }
};
export const fetchCitiesData = (params) => async (dispatch) => {
  try {
    const response = await miscServices.getCitiesData(params);
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.FETCH_CITIES,
        payload: response?.data,
      });
    }
  } catch (error) {
  } finally {
  }
};

export const verifyOrRejectCatalog =
  (payload, nextTabIndex, callback) => async (dispatch) => {
    dispatch({
      type: ActionTypes.CATALOGUE_VERIFY_OR_REJECT_LOADING,
    });
    nProgress.start();
    try {
      const response = await catalogServices.catalogVerifyOrReject(payload);
      if (response.status === 'Success') {
        const sendPayload = {
          ...payload,
          message: response?.message,
        };
        dispatch({
          type: ActionTypes.CATALOGUE_VERIFY_OR_REJECT_SUCCESS,
          payload: sendPayload,
        });

        dispatch(updateCatalogCount(nextTabIndex));
      } else {
        const sendPayload = {
          ...payload,
          message: response?.message,
        };
        dispatch({
          type: ActionTypes.CATALOGUE_VERIFY_OR_REJECT_FAILURE,
          payload: sendPayload,
        });
        toast.error(response?.message);
      }
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      const sendPayload = {
        ...payload,
        message: error?.data?.message?.join(''),
      };
      dispatch({
        type: ActionTypes.CATALOGUE_VERIFY_OR_REJECT_FAILURE,
        payload: sendPayload,
      });
      toast.error(error?.data?.message?.join(''));
    } finally {
      nProgress.done();
    }
  };

export const resetCatalogVerifyMessages = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CATALOGUE_VERIFY_RESET_MESSAGE,
  });
};

export const setComment = (comment) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_COMMENT,
    payload: {
      comment: comment,
    },
  });
};

export const toggleAddCommentModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_ADD_COMMENT_MODAL,
  });
};

export const showAddCommentModal = () => (dispatch) => {
  dispatch(toggleAddCommentModal());
};

export const hideAddCommentModal = () => (dispatch) => {
  dispatch(toggleAddCommentModal());
};

export const fetchZones = (params) => async (dispatch) => {
  try {
    const response = await miscServices.getAllZones(params);
    if (response.status === 'Success') {
      dispatch({
        type: ActionTypes.FETCH_ZONES,
        payload: response?.data,
      });
    }
  } catch (error) {
  } finally {
  }
};
