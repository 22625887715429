import { ActionTypes } from '../actions/actionTypes';
import { getLabelValueData } from '../../utils/helper';

const initialCatalogData = {
  branddetails: {
    brandinfo: {
      companyname: '',
      brandname: '',
      outlets: '',
      brandorigin: undefined,
      establishmentyear: '',
      franchiseyear: '',
      googlerating: '',
      fnbtype: '',
      description: '',
      logo: '',
      businessmodel: [],
    },
    statutoryinfo: {
      gstin: '',
      pan: '',
      tin: '',
      cin: '',
    },
    natureofbusinessinfo: {
      businesscategoryname: [],
      categoryname: [],
      subcategoryname: [],
      othercategory: '',
      ownershiptype: '',
      annualturnover: '',
      ceoname: '',
      websiteurl: '',
      googlebusinessurl: '',
      instagramurl: '',
    },
  },
  franchisedetails: {
    investmentinfo: [
      {
        businesstype: '',
        spacearea: '',
        setupcost: '',
        rawmaterialcost: '',
        licensescost: '',
        miscellaneousexpense: '',
        securitydeposit: '',
        franchisefees: '',
        additionaldetails: '',
      },
    ],
    marketinginfo: {
      marketing: 'brand',
      marketingtype: [],
    },
    masterinfo: {
      country: [],
      state: [],
      city: [],
      masterfranchisefees: '',
      setupcost: '',
      totalinvestment: '',
      royalty: '',
      agreementyears: '',
      roi: '',
    },
    analysisinfo: {
      rent: '',
      electricityexpenses: '',
      royalty: '',
      royalty_unit: 'percent',
      agreementyears: '',
      workingcapital: 500000,
      roi: '',
      miscellaneousexpenses: '',
      totalexpenses: '',
      brand_revenuesharing: '',
      brand_revenuesharing_unit: 'percent',
      franchise_revenuesharing: '',
      franchise_revenuesharing_unit: 'percent',
      securitydeposit: '',
    },
    cityinfo: {
      existingcountry: [],
      existingstate: [],
      existingcity: [],
      expansioncountry: [],
      expansionstate: [],
      expansionscity: [],
      blockcountry: [],
      blockstate: [],
      blockcity: [],
    },
  },
  pagesinfo: {
    aboutus: {
      desc: '',
      img: '',
    },

    support: {
      desc: '',
      img: '',
    },
    awards: [
      {
        desc: '',
        img: '',
      },
    ],
    products: [
      {
        desc: '',
        img: '',
      },
      {
        desc: '',
        img: '',
      },
    ],
    faqs: [
      {
        question: '',
        answer: '',
      },
      {
        question: '',
        answer: '',
      },
    ],
    others: {
      heading: '',
      desc: '',
      img: '',
    },
  },
  additionalinfo: {
    // costbifurcationinfo: {
    //   rent: '',
    //   salary: '',
    //   products: '',
    //   marketing: '',
    //   miscellaneous: '',
    //   wastage: '',
    //   accomadation: '',
    //   otherinfo: '',
    //   profitmargin: '',
    // },
    costbifurcationinfo: {},
    agreementinfo: {
      isagreementfees: '',
      agreementfees: '',
      terms: '',
    },
    traininginfo: {
      noofemployees: '',
      skilledemployees: '',
      nonskilledemployees: '',
      trainingtype: '',
      traininglocation: '',
      recruitmentresp: '',
      trainingperiod: '',
    },
    employeeDetails: {
      headChef: '',
      assistantChef: '',
      waiter: '',
      captain: '',
      restaurantManager: '',
      kitchenSupervisor: '',
      stewardOrWaiter: '',
      cleaner: '',
      kitchenHelper: '',
      commi1: '',
      commi2: '',
    },
    investorprofileinfo: {
      involvement: {
        partTime: false,
        fullTime: false,
        occasionally: true,
      },
      investmentbudget: [500000, 751868],
      jobType: [],
      investmentSource: [],
      location: [],
      industry: [],
    },
    infrastructureAndAutomation: {
      infrastructure: '',
      automation: '',
    },
    salesinfo: {
      monthlysale: '',
      quaterlysale: '',
    },
  },
  photodocinfo: {
    photos: [],
    docs: [],
    menus: [],
  },
  contactdetails: {
    contactinfo: {
      firstname: '',
      lastname: '',
      mobileno: '',
      email: '',
      designation: '',
      landlineno: '',
      address: '',
      landmark: '',
      city: undefined,
      state: undefined,
      country: undefined,
      pincode: '',
    },
    alternatecontact: {
      name: '',
      designation: '',
      mobileno: '',
      email: '',
    },
  },
};

let initialCatalog = {};

const initialState = {
  catalog: initialCatalog,
  tabindex: 0,
  loading: false,
  error: null,
  catalogue_id: null,
  brand_user_id: null,
  status: null,
  isEdit: false,
  catalog_fetching: false,
  catalogDropdowns: {},
  industry: [],
  brandUser: {},
  bussinessCategories: [],
  bussinessTypes: [],
  countries: [],
  states: [],
  cities: [],
  brandUser: {},
  comment: '',
  showCommentModal: false,
  isVerifyingCatalog: false,
  verifyErrorMessage: '',
  verifyMessage: '',
  superCategories: [],
  zones: [],
  costBifurcationData: null,
  employeeDetails: null,
  licenseType: null,
};

export const catalogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_CATALOG:
      return {
        ...state,
        catalog: payload,
      };
    case ActionTypes.SET_CATALOG_COUNT:
      return {
        ...state,
        tabindex: payload,
      };
    case ActionTypes.MANAGE_CATALOG_FORM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.MANAGE_CATALOG_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        catalog_fetching: false,
      };
    case ActionTypes.MANAGE_CATALOG_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: payload?.status || null,
        //isEdit: payload?.status && state.tabindex == 5 ? false : true,
      };
    case ActionTypes.MANAGE_CATALOG_BRAND_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        catalogue_id: payload._id,
        brand_user_id: payload.branduserid,
      };
    case ActionTypes.SET_CATALOG_FORM_EDITABLE:
      return {
        ...state,
        isEdit: payload?.isEdit,
      };

    case ActionTypes.FETCH_CATALOG_DETAILS_LOADING:
      return {
        ...state,
        catalog_fetching: true,
      };
    case ActionTypes.FETCH_CATALOG_DETAILS_SUCCESS:
      // console.log('FETCH_CATALOG_DETAILS_SUCCESS', payload);
      const {
        branddetails: branddetailsData,
        additionalinfo,
        franchisedetails,
        pagesinfo,
        photodocinfo,
        contactdetails,
        status,
        _id,
        branduserid,
      } = payload || {};

      let modifiedBranddetails = {
        ...branddetailsData,
        natureofbusinessinfo: {
          ...branddetailsData?.natureofbusinessinfo,
          businesscategoryname: getLabelValueData(
            branddetailsData?.natureofbusinessinfo?.businesscategoryname
          ),
          categoryname: getLabelValueData(
            branddetailsData?.natureofbusinessinfo?.categoryname
          ),
          subcategoryname: getLabelValueData(
            branddetailsData?.natureofbusinessinfo?.subcategoryname
          ),
        },
        statutoryinfo: {
          ...branddetailsData?.statutoryinfo,
          licensetype: getLabelValueData(
            branddetailsData?.statutoryinfo?.licensetype
          ),
        },
      };
      // console.log('modifiedBranddetails', modifiedBranddetails);
      // console.log('branddetails', state.catalog.branddetails);

      let modifiedFranchiseDetails = {
        ...franchisedetails,
        cityinfo: {
          // Existing city info
          existingcountry: getLabelValueData(
            franchisedetails?.cityinfo?.existingcity?.[0]?.country || ''
          ),
          existingstate: getLabelValueData(
            franchisedetails?.cityinfo?.existingcity?.[0]?.state || ''
          ),
          existingcity: getLabelValueData(
            franchisedetails?.cityinfo?.existingcity?.[0]?.city || ''
          ),

          // Expansion city info
          expansioncountry: getLabelValueData(
            franchisedetails?.cityinfo?.expansioncity?.[0]?.country || ''
          ),
          expansionstate: getLabelValueData(
            franchisedetails?.cityinfo?.expansioncity?.[0]?.state || ''
          ),
          expansionscity: getLabelValueData(
            franchisedetails?.cityinfo?.expansioncity?.[0]?.city || ''
          ),

          // Blocked city info
          blockcountry: getLabelValueData(
            franchisedetails?.cityinfo?.blockcity?.[0]?.country || ''
          ),
          blockstate: getLabelValueData(
            franchisedetails?.cityinfo?.blockcity?.[0]?.state || ''
          ),
          blockcity: getLabelValueData(
            franchisedetails?.cityinfo?.blockcity?.[0]?.city || ''
          ),
        },

        masterinfo: {
          ...franchisedetails?.masterinfo,
          country: getLabelValueData(
            franchisedetails?.masterinfo?.country || ''
          ),
          state: getLabelValueData(franchisedetails?.masterinfo?.state || ''),
          city: getLabelValueData(franchisedetails?.masterinfo?.city || ''),
        },

        investmentinfo:
          franchisedetails?.investmentinfo?.map((obj) => ({
            ...obj,
            businesstype: {
              label: obj?.businesstype?.name || '',
              value: obj?.businesstype?._id || '',
            },
          })) || [],

        marketinginfo: {
          ...franchisedetails?.marketinginfo,
          marketingtype: getLabelValueData(
            franchisedetails?.marketinginfo?.marketingtype || ''
          ),
        },

        analysisinfo: {
          ...franchisedetails?.analysisinfo,
          brand_revenuesharing:
            franchisedetails?.analysisinfo?.revenuesharing?.brand?.value || '',
          brand_revenuesharing_unit:
            franchisedetails?.analysisinfo?.revenuesharing?.brand?.type || '',
          franchise_revenuesharing:
            franchisedetails?.analysisinfo?.revenuesharing?.franchise?.value ||
            '',
          franchise_revenuesharing_unit:
            franchisedetails?.analysisinfo?.revenuesharing?.franchise?.type ||
            '',
          royalty_unit: franchisedetails?.analysisinfo?.royalty?.type || '',
          royalty: franchisedetails?.analysisinfo?.royalty?.value || '',
        },
      };

      // console.log('modifiedFranchiseDetails----22', modifiedFranchiseDetails);

      let modifiedAdditionalInfo = {
        ...additionalinfo,
        investorprofileinfo: {
          ...additionalinfo?.investorprofileinfo,
          industry: getLabelValueData(
            additionalinfo?.investorprofileinfo?.industry
          ),
        },
      };

      let contactdetailsData = {
        ...contactdetails,
        contactinfo: {
          ...contactdetails?.contactinfo,
          city: {
            label: contactdetails?.contactinfo?.city?.name,
            value: contactdetails?.contactinfo?.city?._id,
          },
          country: {
            label: contactdetails?.contactinfo?.country?.name,
            value: contactdetails?.contactinfo?.country?._id,
          },
          state: {
            label: contactdetails?.contactinfo?.state?.name,
            value: contactdetails?.contactinfo?.state?._id,
          },
        },
      };
      //console.log('modifiedAdditionalInfo', modifiedAdditionalInfo);

      let catalog = {
        branddetails: branddetailsData
          ? modifiedBranddetails
          : state?.catalog?.branddetails,
        franchisedetails: franchisedetails
          ? modifiedFranchiseDetails
          : state?.catalog?.franchisedetails,
        pagesinfo: pagesinfo || state?.catalog?.pagesinfo,
        additionalinfo: additionalinfo
          ? modifiedAdditionalInfo
          : state?.catalog?.additionalinfo,
        photodocinfo: photodocinfo || state?.catalog?.photodocinfo,
        contactdetails: contactdetailsData || state?.catalog?.contactdetails,
      };
      console.log('catalog', catalog);

      return {
        ...state,
        catalog_fetching: false,
        loading: false,
        catalog: catalog,
        catalogue_id: _id || null,
        brand_user_id: branduserid || null,
        status: status || null,
        // isEdit: status === 'UNDER_VERIFICATION' ? false : true,
      };

    case ActionTypes.FETCH_CATALOG_DETAILS_FAILURE:
      return {
        ...state,
        catalog_fetching: false,
        error: payload,
        catalog: initialCatalog,
        tabindex: 0,
        catalouge_id: null,
        catalogue_id: null,
        brand_user_id: null,
        status: null,
        loading: false,
      };
    case ActionTypes.UPDATE_CATELOG:
      const { catelogSection, data } = payload;
      let tempCatelog = { ...state.catalog };
      tempCatelog[catelogSection] = data;
      return {
        ...state,
        catalog: tempCatelog,
      };
    case ActionTypes.RESET_CATALOG:
      return {
        ...state,
        catalog: initialCatalog,
        tabindex: 0,
        catalouge_id: null,
        brand_user_id: null,
        status: null,
        // isEdit: true,
      };
    case ActionTypes.GET_DROPDOWN_DATA:
      return {
        ...state,
        catalogDropdowns: payload.data,
      };
    case ActionTypes.GET_INDUSTRY:
      return {
        ...state,
        industry: payload.data,
      };

    case ActionTypes.GET_BUSSINESS_CATEGORY_DATA:
      return {
        ...state,
        bussinessCategories: payload?.data?.categories,
      };
    case ActionTypes.GET_BUSSINESS_TYPE_DATA:
      return {
        ...state,
        bussinessTypes: payload.data,
      };
    case ActionTypes.FETCH_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };
    case ActionTypes.FETCH_STATES:
      return {
        ...state,
        states: payload,
      };
    case ActionTypes.FETCH_CITIES:
      return {
        ...state,
        cities: payload,
      };
    case ActionTypes.FETCH_ZONES:
      return {
        ...state,
        zones: payload,
      };
    case ActionTypes.GET_COST_BIFURCATION_DATA:
      return {
        ...state,
        costBifurcationData: payload,
      };
    case ActionTypes.GET_EMPLOYEE_DETAILS_DATA:
      return {
        ...state,
        employeeDetails: payload,
      };
    case ActionTypes.GET_LICENSE_TYPE_DATA:
      return {
        ...state,
        licenseType: payload,
      };
    case ActionTypes.GET_SUPER_CATEGORY_DATA:
      return {
        ...state,
        superCategories: payload.data,
      };
    case ActionTypes.CATALOGUE_VERIFY_OR_REJECT_LOADING:
      return {
        ...state,
        isVerifyingCatalog: true,
        verifyErrorMessage: '',
        verifyMessage: '',
      };
    case ActionTypes.CATALOGUE_VERIFY_OR_REJECT_SUCCESS:
      let catalogSuccessData = {
        ...state.catalog,
        [payload.pagename]: {
          ...state.catalog[payload.pagename],
          verificationinfo: {
            status: payload.status,
            comment: payload.comments,
          },
        },
      };

      return {
        ...state,
        catalog: catalogSuccessData,
        isVerifyingCatalog: false,
        verifyMessage: payload?.message,
        verifyErrorMessage: '',
      };
    case ActionTypes.CATALOGUE_VERIFY_OR_REJECT_FAILURE:
      let catalogFailurData = {
        ...state.catalog,
        [payload.pagename]: {
          ...state.catalog[payload.pagename],
          verificationinfo: {
            status: payload?.status,
            comment: payload?.comments,
          },
        },
      };

      return {
        catalog: catalogFailurData,
        ...state,
        isVerifyingCatalog: false,
        verifyErrorMessage: payload?.message,
        verifyMessage: '',
      };
    case ActionTypes.CATALOGUE_VERIFY_RESET_MESSAGE:
      return {
        ...state,
        isVerifyingCatalog: false,
        verifyErrorMessage: '',
        verifyMessage: '',
      };
    case ActionTypes.SET_BRAND_USER:
      return {
        ...state,
        brandUser: payload,
      };
    case ActionTypes.SET_NEW_CATALOG_DATA:
      console.log('payload', payload);

      let modifiedBranddetailsData = {
        brandinfo: {
          brandname: payload?.brandname,
          companyname: payload?.companyname,
        },
        natureofbusinessinfo: {
          businesscategoryname: [
            {
              label: payload?.business_category?.name,
              value: payload?.business_category?._id,
            },
          ],
        },
        statutoryinfo: {
          gstin: payload?.gst,
        },
      };

      let modifiedContactdetailsData = {
        contactinfo: {
          firstname: payload?.firstname,
          lastname: payload?.lastname,
          email: payload?.email,
          mobileno: payload?.mobile,
        },
      };
      // let modifiedFranchiseDetailsData = {
      //   investmentinfo: initialCatalogData?.franchisedetails?.investmentinfo,
      // };

      let catalogData = {
        ...initialCatalogData,
        branddetails: modifiedBranddetailsData,
        contactdetails: modifiedContactdetailsData,
        //franchisedetails: modifiedFranchiseDetailsData,
      };
      return {
        ...state,
        catalog: catalogData,
      };

    case ActionTypes.SET_COMMENT:
      return {
        ...state,
        comment: payload?.comment || '',
      };
    case ActionTypes.TOGGLE_ADD_COMMENT_MODAL:
      return {
        ...state,
        showCommentModal: !state.showCommentModal,
      };
    case ActionTypes.RESET_CATALOG:
      return {
        ...state,
        catalog: {},
        tabindex: 0,
        catalogue_id: null,
        brand_user_id: null,
        status: null,
        isEdit: false,
        catalogDropdowns: {},
        brandUser: {},
        comment: '',
        isVerifyingCatalog: false,
        verifyErrorMessage: '',
        verifyMessage: '',
      };
    default:
      return state;
  }
};
