import { API_ENDPOINTS } from '../constants/endpoints';
import { apiClient } from '../config/planClient';
const { INVOICE } = API_ENDPOINTS;

export const getAllInvoices = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.getAllInvoices();
      const res = await apiClient({
        url: url,
        method,
        params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const createInvoice = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.createInvoice();
      const res = await apiClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateInvoice = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.updateInvoice();
      const res = await apiClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const generateInvoice = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.generateInvoice();
      const res = await apiClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const proformaToInvoice = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.proformaToInvoice();
      const res = await apiClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const downloadInvoice = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.downloadInvoice();
      const res = await apiClient({
        url: url,
        method,
        params,
        responseType: 'blob',
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const exportInvoice = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.exportInvoice();
      const res = await apiClient({
        url: url,
        method,
        params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const dashboardInvoice = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.dashboardInvoice();
      const res = await apiClient({
        url: url,
        method,
        params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const exportAllInvoices = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = INVOICE.exportAllInvoices();
      const res = await apiClient({
        url: url,
        method,
        params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
