const hasStorage = typeof localStorage !== "undefined";

const getItem = (key) => (hasStorage ? localStorage.getItem(key) : null);

const setItem = (key, value) => {
  if (hasStorage) localStorage.setItem(key, value);
};

const getParsedItem = (key) => JSON.parse(getItem(key));

const setParsedItem = (key, value) => setItem(key, JSON.stringify(value));
const removeItem = (key) => localStorage.removeItem(key);

export { getItem, setItem, getParsedItem, setParsedItem, removeItem };
